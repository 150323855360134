.banner,
.mobileBanner {
	position: relative;
}

.banner {
	/* min-width: 176px; */
	max-width: 225px;
	height: 176px;

	border-radius: 12px;
	transform-style: preserve-3d;
	perspective: 1000px;
}

.notresize {
	width: 176px;
	min-width: unset;
}

.backgroundImg {
	position: absolute;
	inset: 0;
}

.backgroundImg img {
	border-radius: 8px;
}

.front,
.back {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	padding: 12px;

	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);

	border-radius: 10px;
	box-shadow: 0 4px 10px rgb(0 0 0 / 2%);
	backface-visibility: hidden;
}

.front {
	display: flex;

	padding: 14px;

	transform: rotateY(0deg);
}

.back {
	padding: 14px;

	transform: rotateY(180deg);

	background: #E6E6E6;
}

.banner.isRotatable:hover .front {
	transform: rotateY(-180deg);
}

.banner.isRotatable:hover .back {
	transform: rotateY(0deg);
}

.isRotatable {
	display: flex;
}

.title {
	z-index: 1;

	letter-spacing: -0.01em;

	color: #FFF;

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.text {
	letter-spacing: -0.01em;

	color: #FFF;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.promoCode {
	position: absolute;
	z-index: 2;
	bottom: 14px;
	left: 14px;

	padding: 6px 8px 5px;

	cursor: pointer;
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: #F9594C;
	border-radius: 4px;
	background: #FFF;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.backPromoCodeText {
	overflow: hidden;

	width: calc(100% - 28px);

	letter-spacing: -0.01em;
	text-transform: uppercase;
	text-overflow: ellipsis;

	color: var(--color-black);

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.backPromoCode {
	position: absolute;
	bottom: 14px;
	left: 14px;

	display: flex;

	width: calc(100% - 28px);
	height: 26px;
	padding: 4px 8px;

	cursor: pointer;

	border-radius: 4px;
	background: #FFF;
	gap: 10px;
}

.backText {
	overflow-y: auto;

	max-height: calc(100% - 34px);

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.backText::-webkit-scrollbar-track {
	border-radius: 5px;
}

.backTextCopied {
	position: absolute;
	top: 100%;
	left: calc(50% - 8px);

	visibility: hidden;

	padding: 4px 8px;

	cursor: default;
	user-select: none;
	transition: all 0.3s linear;
	transform: translateX(-50%);

	opacity: 0;
	color: var(--color-gray-dark);
	border-radius: 4px;
	background: #FFF;
}

.backTextCopied.isCopied {
	top: 50%;

	visibility: visible;

	opacity: 1;
}

.backTextFull {
	max-height: 100%;
}
