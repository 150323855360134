.wrapper {
	position: relative;

	/* display: flex;
	align-items: center; */
}

.marginBottom {
	margin-bottom: 32px;
}

.slider {
	margin-top: 28px;
	padding: 0;
}

.sliderWrapper {
	overflow: hidden;

	/* width: 100%; */
	height: 176px;
}

.slide {
	position: relative;

	height: 148px;
}

.slideImage {
	overflow: hidden;

	/* border-radius: 16px; */
}

.pagination {
	display: none !important;
	justify-content: center;
	gap: 8px;

	margin: 12px 0;
}

.visiblePagination {
	display: flex !important;
}

.paginationHide {
	display: none;
}

.bulletClass {
	width: 8px;
	height: 8px;

	cursor: pointer;

	border-radius: 50%;

	background: #D5D3D3;
}

.bulletClass:nth-child(1):nth-last-child(1) {
	display: none;
}

.bulletActiveClass {
	background: #686666;
}

.sliderBtnPrev,
.sliderBtnNext {
	position: absolute;
	z-index: 990;
	top: 50%;

	display: none;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 0;
	height: 0;

	margin: 0;
	padding: 0;

	transform: translate(-100%) translateY(-50%);

	opacity: 0;

	border: none;
	border-radius: 50%;
	background: transparent;
}

.visibleSliderBtn {
	display: unset !important;

	width: 46px;
	height: 46px;
	padding: 0;

	cursor: pointer;

	opacity: 1;
	background: #FFF;
	box-shadow: 3px 2px 15px 5px rgb(0 0 0 / 6%);
}

.sliderBtnPrev {
	left: 35px;

	transform: translate(calc(-100%)) translateY(-50%)  rotate(180deg);
}

.sliderBtnNext {
	right: 35px;

	transform: translate(100%) translateY(-50%);
}

.visibleSliderBtn:disabled,
.visibleSliderBtn:disabled {
	animation: disabledButton 1s ease-in-out forwards;
}

@keyframes disabledButton {
	from {
		pointer-events: auto;

		opacity: 1;
	}

	to {
		pointer-events: none;

		opacity: 0;
	}
}

.sliderBtnPrev svg,
.sliderBtnNext svg {
	transition: stroke 0.4s ease;
}

.sliderBtnPrev:hover svg,
.sliderBtnNext:hover svg {
	stroke: var(--color-primary);
}

.hiddenButton {
	display: none;
}
