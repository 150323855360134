.sliderCategories {
	position: relative;

	z-index: 0;

	overflow: hidden;

	margin: 0 -16px;

	padding: 0;
}

.sliderCategories::after,
.sliderCategories::before {
	display: none;
}

.title {
	margin-bottom: 16px;
	padding: 0 16px;

	color: var(--color-black);

	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

.safeArea {
	overflow: visible !important;

	padding: 0 16px !important;
}

.sliderWrapper {
	height: 294px;
}

.sliderGrid {
	display: grid;
	overflow: hidden;

	width: 100vw;
	padding: 0;
	grid-gap: 0 8px;
	justify-items: center;
	grid-template-rows: repeat(2, 1fr);
	grid-template-columns: repeat(3, 1fr);
}

.sliderGridTablet {
	grid-template-columns: repeat(6, 1fr);
}

.sliderItem {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	width: 76px;
	height: 128px;

	border-radius: 8px;
}

.aciveItem {
	background: rgb(255 76 48 / 8%);
}

.sliderItemTitle {
	overflow: hidden;

	height: 32px;

	text-align: center;
	white-space: normal;
	letter-spacing: 0.01em;
	text-overflow: ellipsis;
	word-break: break-word;

	color: var(--color-black);

	font-size: 14px;

	font-weight: 400;
	line-height: 16px;
}

.imageWrapper {
	position: relative;

	overflow: hidden;

	width: 76px;
	height: 80px;
}

.percentImg {
	width: 76px;
	height: 80px;

	background-image: url("~/src/assets/svg/percent.png");
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: contain;
}
