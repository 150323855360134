.wrapper {
	width: 100%;
}

.paymentsList {
	overflow-y: auto;

	max-height: 216px;
}

.payment {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	padding: 7px 0;

	transition: background-color 0.3s ease;

	border-radius: 12px;
}

.payment:not(:last-child) {
	margin-bottom: 12px;
}

.paymentText {
	margin-right: auto;

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.paymentIcon {
	flex: 0 0 auto;

	width: 45px;
	height: 30px;
	margin-right: 8px;
}

.checkIcon {
	display: none;
	flex: 0 0 auto;

	width: 24px;
	height: 24px;
}

.pseudoInput {
	position: relative;

	display: block;

	width: 30px;
	height: 30px;

	border-radius: 50%;
	background-color: #E7E7E7;
	box-shadow: inset 0 0 5px 0 #AFAFAF;
}

.input {
	display: none;
}

.checked .pseudoInput::before {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 18px;
	height: 18px;

	content: "";
	transform: translate(-50%, -50%);

	border-radius: 50%;
	background-color: rgb(255 255 255);
	box-shadow: 0 0 5px 4px #ECECEC;
}
