/* stylelint-disable declaration-block-no-duplicate-properties */
/* stylelint-disable order/properties-order */
.cart {
	width: 100%;
	height: 100%;
}

.separateLine {
	width: 100%;
	height: 1px;
	background-color: #EBEBEB;
	margin: 16px 0;
}

.cartContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.cartTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cartTopTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.image {
	flex: 0 0 auto;
	width: 48px;
	height: 48px;
	margin-right: 12px;
	border-radius: 50%;
	overflow: hidden;
}

.cleareBasket {
	font-weight: 500;
	font-size: 16px;
	letter-spacing: -0.025em;
	color: var(--color-gray);
	cursor: pointer;
	transition: color 0.2s ease;
}

.cleareBasket:hover {
	color: var(--color-gray-dark);
}

.cartBody {
	max-height: calc(100vh - 220px);
	padding-right: 16px;
	margin-right: -24px;
	overflow: hidden;
	overflow-y: scroll;
	flex: 1 1 auto;
}
