.wrapper {
	width: 246px;
}

.title {
	margin: 16px 0 12px;

	text-align: center;
}

.rangeFlex {
	display: flex;
	justify-content: space-between;

	padding: 8px;

	border: 1px solid #EBEBEB;
	border-radius: 24px;
}

.buttonRangeType {
	padding: 0 18px;

	cursor: pointer;
	text-transform: uppercase;

	color: var(--color-gray);
	border-radius: 16px;

	font-size: 16px;
	font-weight: 700;
	line-height: 42px;
}

.activeButton {
	color: var(--color-white);
	background-color: #FF4C30;
}

.timeList {
	display: flex;
	overflow-x: hidden;
	overflow-y: auto;
	flex: 0 1 auto;
	flex-flow: row wrap;

	max-height: 334px;
	margin: 0 -20px 0 0;
	padding: 0;
	padding-right: 16px;

	color: black;

	line-height: 30px;
}

.alertText {
	margin: 40px auto;

	text-align: center;

	font-size: 20px;
	font-weight: 600;
}

.itemTimeList {
	display: flex;
	flex: 0 0 100%;
	justify-content: space-between;

	margin-top: 6px;
	padding: 16px 12px;

	cursor: pointer;
	transition: background-color 0.2s ease;

	color: #212529;
	border-radius: 16px;

	font-size: 18px;

	font-weight: 600;
	line-height: 26px;
}

.itemTimeList:hover {
	transition: background-color 0.2s ease;

	background-color: var(--color-fill-hover);
}

.selectedItemList {
	transition: background-color 0.2s ease;

	background-color: var(--color-fill-hover);
}
