/* stylelint-disable declaration-block-no-duplicate-properties */
/* stylelint-disable order/properties-order */
.cart {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.cartHeader {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 16px;
	border-bottom: 1px solid #EBEBEB;
}

.cartBody {
	flex: 1 1 auto;
	margin-right: -20px;
	padding: 16px 0;
	padding-right: 12px;
	overflow: hidden;
	overflow-y: scroll;
}

.clearBasket {
	width: 44px;
	height: 44px;
	border-radius: 12px;
	background-color: var(--color-gray-light);
}

.clearBasket:hover {
	background-color: var(--color-gray-light-hover);
}

.clearBasket:active {
	background: #DFDFDF;
}

.empty {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 0 30px;
}

.emptyText {
	margin-top: 24px;

	text-align: center;

	color: var(--color-overlay);

	font-size: 22px;
	line-height: 30px;
}

.cartFooter {
	flex: 0 0 auto;
}

.cartFooter .shippengPlate {
	margin: 0;
	margin-top: 12px;
}

.metaInfo {
	display: flex;
	justify-content: space-between;
	padding: 12px 0;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
}

.metaItem {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 0 0 auto;
	height: 40px;
}

.metaDelivery {
	flex: 1 1 auto;
	flex-direction: column;
	align-items: flex-start;
	border-right: 1px solid #EBEBEB;
	margin-right: 16px;
	padding-right: 16px;
}

.deliverySumm {
	text-align: right;
}

.metaItem .text {
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-weight: 500;
	font-size: 16px;
	color: var(--color-black);
}

.metaItem .subText {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.01em;
	white-space: nowrap;
	color: var(--color-gray-dark);
}

.metaItem .deliveryInfoButton {
	position: unset;
	height: 22px;
	font-size: 16px;
}

.deliveryInfoButton::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.penEditIcon {
	width: 30px;
	height: 30px;
	margin-left: 8px;
	padding: 3px;
	background-color: var(--color-gray-light);
	border-radius: 8px;
}

.checkout {
	padding-top: 12px;
}

.checkoutBtn {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 56px;
	padding: 0 14px;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
	letter-spacing: -0.025rem;
}

.leftBlock {
	position: relative;
	flex: 1 1 auto;
	padding-right: 12px;
	letter-spacing: 0.1em;
	text-align: right;
	border-right: 1px solid #FFF;
}

.rightBlock {
	flex: 1 1 auto;
	padding-left: 12px;
	text-align: left;
	text-transform: initial;
}

.cartIcon {
	width: 18px;
	height: 18px;
	margin-right: 12px;
	vertical-align: text-top;
}

.cartIcon path {
	stroke: #FFF;
}
