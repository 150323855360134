.tooltipWrapper {
	position: fixed;
	z-index: 10002;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	/* width: 100vw;
  height: 100vh; */
	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;
}

.tooltip {
	position: absolute;

	max-width: 18vw;
	padding: 12px 16px;

	border-radius: 12px;
	background: var(--color-white);
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 6px 3px rgb(0 0 0 / 8%);
}

.tooltip.open {
	animation: fade 0.2s ease-in forwards;
}

.tooltip.close {
	animation: fade-out 0.2s ease-out forwards;
}

@keyframes fade {
	from {
		transform: translateY(-8%);

		opacity: 0;
	}

	to {
		transform: translateY(0);

		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		transform: translateY(0);

		opacity: 1;
	}

	to {
		transform: translateY(-8%);

		opacity: 0;
	}
}
