.wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.addressStage {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.detailsStage {
	position: fixed;

	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;

	padding: 0;
	padding-bottom: max(calc(0px + var(--safe-area-inset-bottom, 0px)), 24px);
}

.addressStageHidden {
	z-index: 100;

	opacity: 0;
}

.addressStageVisible {
	z-index: 1000;

	opacity: 1;
}

.addressStage2Hidden {
	z-index: 100;

	opacity: 0;
}

.addressStage2Visible {
	z-index: 1000;

	opacity: 1;
}

.addressStageHidden,
.addressStageVisible,
.addressStage2Hidden,
.addressStage2Visible {
	transition: opacity 0.3s ease;
}

.closeButton {
	position: absolute;
	z-index: 101;
	top: 12px;
	right: 12px;

	width: 42px;
	height: 42px;
	margin-top: 0;
	margin-left: auto;
	padding: 0;

	cursor: pointer;
	transform: unset;

	border: none;
	border-radius: 50%;
	background-color: var(--color-white);
	box-shadow: 0 4px 2px 2px rgb(68 65 65 / 10%);
}

.closeIcon {
	display: unset;

	width: 24px;
	height: 24px;

	vertical-align: middle;
}

.mapContainer {
	/* position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 132px; */
	width: 100%;
	height: 100%;
}

.safeAreaLine {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;

	height: max(var(--safe-area-inset-top, 0), 0px);
}

.stickyBlock {
	position: sticky;
	top: 0;

	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
	margin-bottom: 16px;
}

.searchBar {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;

	width: 100%;
}

.closeTextButton {
	position: relative;

	padding-right: 12px;
	padding-left: 14px;

	font-size: 16px;
	font-weight: 400;
}

.closeTextButton::before {
	position: absolute;
	top: 25%;
	left: 0;

	width: 1px;
	height: 50%;

	content: "";

	background-color: var(--color-border);
}

.addressDetails {
	display: flex;
	flex-direction: column;

	padding: 24px 16px;

	transition: all 0.2s linear;
	pointer-events: none;

	opacity: 0;
}

.subDetails {
	display: grid;
	gap: 8px 8px;

	grid-template-columns: 1fr 1fr;
}

.preference {
	width: 100%;
	height: 62px;
	margin-top: 12px;

	resize: none;

	font-weight: 400;
}

.bottomBar {
	position: absolute;
	z-index: 1000;
	right: 0;
	bottom: 24px;
	left: 0;

	width: 100%;
	padding: 0 16px;
	padding-bottom: 24px;
	padding-bottom: max(calc(0px + var(--safe-area-inset-bottom, 0px)), 0px);

	transition: all 0.2s linear;
	pointer-events: initial;

	opacity: 1;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.selectMapButton {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	margin: 12px 0;

	transition: all 0.2s linear;
	pointer-events: none;

	opacity: 0;
}

.locationIcon {
	width: 20px;
	height: 20px;
	margin-right: 6px;
}

.locationIcon path {
	stroke: #FF4C30;
}

.saveButton {
	width: 100%;

	transition: all 0.2s linear;
	text-transform: uppercase;
	pointer-events: none;

	opacity: 0;
}

.visible {
	transition: all 0.2s linear;
	pointer-events: initial;

	opacity: 1;
}
