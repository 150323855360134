.partnerAbout {
	display: flex;
	flex-direction: column;

	width: 100%;
	margin-bottom: 12px;

	background: none;
}

@media screen and (min-width: 540px) {
	.partnerAbout {
		margin-bottom: 16px;
	}
}

.topBar {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 12px;
}

.imageWrapper {
	position: relative;

	overflow: hidden;

	width: 48px;
	height: 48px;

	border-radius: 50%;
	background-color: rgb(202 202 202);
}

.titleBlock {
	flex-grow: 1;

	margin: 0 8px;
}

.partnerShortName {
	letter-spacing: -0.01em;

	color: #999;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.favorite {
	display: flex;
}

.buttonsWrapper {
	display: flex;
	overflow: auto;
	flex-wrap: nowrap;

	width: 100%;
	margin: -6px 0;

	white-space: nowrap;

	color: var(--color-black);
}
