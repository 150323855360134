.wrapper {
	max-width: 1600px;
	padding-bottom: 32px;
}

.content {
	position: relative;
}

.marginTop {
	margin-top: 24px;
}

.marginBottom {
	margin-bottom: 24px;
}

.partnerItem {
	display: flex;
	align-items: center;
	flex-direction: column;

	min-width: 132px;
	height: 152px;
}

.backgroundImg {
	position: relative;

	overflow: hidden;

	width: 96px;
	height: 96px;

	border-radius: 50%;
	background: white;
	box-shadow: 0 3px 10px rgb(0 0 0 / 4%);
}

.partnerName {
	margin-top: 12px;

	text-align: center;

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.slider {
	margin-top: 28px;
	padding: 0;
}

.sliderWrapper {
	overflow: hidden;
}

.slide {
	position: relative;

	height: 152px;
}

.slideImage {
	overflow: hidden;

	border-radius: 16px;
}

.pagination {
	display: flex;
	justify-content: center;
	gap: 8px;

	margin: 14px 0 10px;
}

.paginationHide {
	display: none;
}

.bulletClass {
	width: 8px;
	height: 8px;

	cursor: pointer;

	border-radius: 50%;

	background: #D5D3D3;
}

.bulletClass:nth-child(1):nth-last-child(1) {
	display: none;
}

.bulletActiveClass {
	background: #686666;
}

.sliderBtnPrev,
.sliderBtnNext {
	position: absolute;
	z-index: 990;
	top: 50%;

	display: none !important;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 46px;
	height: 46px;

	margin: 0;
	padding: 2px;

	cursor: pointer;

	transform: translate(-100%) translateY(-50%);

	border: none;
	border-radius: 50%;
	background: #FFF;
	box-shadow: 3px 2px 15px 5px rgb(0 0 0 / 6%);
}

.visibleSliderBtn {
	display: unset !important;
}

.sliderBtnPrev {
	left: 35px;

	transform: translate(calc(-100%)) translateY(-50%)  rotate(180deg);

	box-shadow: 3px -2px 15px 5px rgb(0 0 0 / 6%);
}

.sliderBtnNext {
	right: 35px;

	transform: translate(100%) translateY(-50%);

	box-shadow: 3px -2px 15px 5px rgb(0 0 0 / 6%);
}

.sliderBtnPrev:disabled,
.sliderBtnNext:disabled {
	animation: disabledButton 1s ease-in-out forwards;
}

@keyframes disabledButton {
	from {
		pointer-events: auto;

		opacity: 1;
	}

	to {
		pointer-events: none;

		opacity: 0;
	}
}

.sliderBtnPrev svg,
.sliderBtnNext svg {
	transition: stroke 0.4s ease;
}

.sliderBtnPrev:hover svg,
.sliderBtnNext:hover svg {
	stroke: var(--color-primary);
}

.hiddenButton {
	display: none;
}
