.wrapper {
	display: flex;
	align-items: center;

	height: 22px;

	cursor: pointer;
}
/* stylelint-disable */
  
.input {
	position: absolute;
	z-index: -1;

 
	display: none;

	opacity: 0;
}

.input   {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.checkbox {
	display: inline-block;
	flex-grow: 0;
	flex-shrink: 0; 

	width: 20px;
	height: 20px;
	margin-right: 0.5em;

	content: "";

	border: 1px solid var(--color-overlay);
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.checkbox__checked {
	border-color: transparent;
 position: relative;
	background-color: var(--color-primary);
 
}

.checkbox__checked::after {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	content: '';
	width: 6px;
	height: 6px;
	background-color: var(--color-white);
	border-radius: 50%;
}