.filters {
	display: flex;

	overflow-x: auto;
	overflow-y: hidden;
	align-items: center;
	flex-wrap: nowrap;

	margin: 0 -16px;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none;
}

.filters::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.tagItem {
	padding-right: 8px;
	padding-bottom: 16px;
}

.tagItem:last-child {
	padding-right: 0;
}

.tags {
	position: relative;

	width: max-content;
}

.tagsContainer {
	position: relative;

	display: flex;
	flex-wrap: nowrap;

	margin: 0 16px;
}

.tag {
	box-shadow: 1px 3px 8px rgb(0 0 0 / 4%);
}

.arrowIcon {
	margin-left: 6px;

	pointer-events: none;
}

.activeIcon path {
	stroke: var(--color-white);
}

.activeSortIcon path {
	stroke: var(--color-white);
	fill: var(--color-white);
}

.sortButton {
	color: transparent;

	font-size: 0;
}
