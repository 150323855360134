.wrapper {
	display: flex;

	align-items: center;
	flex-direction: column;
	justify-content: center;

	width: 100%;
	height: 100%;
	margin: auto 0;
	margin-top: 4%;
}

.imgWrapper {
	min-width: 222px;
	max-width: 342px;
	min-height: 222px;
	max-height: 342px;

	cursor: pointer;
	justify-self: flex-start;
}

.img {
	min-width: 222px;
	max-width: 342px;
	min-height: 222px;
	max-height: 342px;

	cursor: default;
}

.jdunToptext {
	margin-top: 32px;

	text-align: center;

	font-size: 28px;
	font-weight: 600;
	line-height: 36px;
}

.jdunBottomText {
	max-width: 460px;
	margin-top: 32px;

	text-align: center;

	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}
