.metaPlate {
	display: flex;
	align-items: center;

	padding-right: 6px;

	cursor: pointer;
	transition: 0.3s ease;

	border-radius: 12px;
}

.metaPlate:hover {
	transition: 0.3s ease;

	background: #F0EEEE;
}

.metaPlateInfo {
	margin: auto 2px;

	text-align: left;
}

.metaPlateIcon {
	display: flex;
	align-items: center;

	height: 40px;
	margin-right: 8px;
	padding: 0 10px;

	letter-spacing: 0.05em;

	color: var(--color-black);
	border-radius: 12px 12px 0;
	background: #F0EEEE;

	font-size: 18px;

	font-weight: 700;
	line-height: 20px;
}

.metaSubTitle {
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: var(--color-black);

	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
}

.metaSubText {
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: #757575;

	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	text-decoration-line: underline;
}

.aboutDelivery {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.modalTitle {
	margin: 0;

	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

.deliveryItemInfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
}

.deliveryAmount {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.modalItemTitle {
	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}
