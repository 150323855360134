.pay {
	margin: 0 -16px -16px;

	background-color: var(--color-silver);
}

.form {
	width: 100%;
	margin-bottom: 8px;
	padding: 16px;
	padding-top: 0;

	border-radius: 0 0 16px 16px;
	background-color: var(--color-white);
}

.block {
	margin-bottom: 16px;
	padding: 16px;

	border-radius: 16px 16px 24px 24px;
	background-color: var(--color-white);
}

.form textarea::placeholder {
	text-align: center;
}

.preference {
	width: 100%;
	height: 44px;
}

.promocodeButton {
	margin-top: 12px;
}

.buttonsWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding: 16px;
	padding-bottom: 24px;

	border-radius: 24px 24px 0 0;
	background-color: var(--color-white);
}

.orderPay {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
}

.totalSumm {
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;

	margin: 0;
	margin-right: 16px;

	white-space: nowrap;
}

.supText {
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.underText {
	text-decoration: line-through;

	color: var(--color-black);
}

.itemSumm {
	color: var(--color-black);

	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

.buttonPay {
	flex: 1 1 auto;

	font-size: 16px;
	font-weight: 400;
}

.separateLine {
	height: 1px;
	margin: 6px 0;

	background-color: #F0EFEF;
}
