.filled svg {
	fill: var(--color-primary);

	outline: none;
}

.star svg {
	margin-right: 5px;

	outline: none;
}

.editable {
	cursor: pointer;

	outline: none;
}
