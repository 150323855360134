.wrapper {
	width: 100%;
}

.form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 12px;

	padding-bottom: 16px;
}

.twoColumns,
.oneColumn {
	height: 44px;
}

.twoColumns {
	grid-column-start: 1;
	grid-column-end: 3;
}

.addressSelect {
	display: flex;
	justify-content: center;

	height: 44px;
	margin-bottom: 12px;
	padding: 0 14px;
}

.saveAddress {
	width: 100%;

	text-transform: uppercase;
}
