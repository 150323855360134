.desktopPartnerCard {
	position: relative;

	height: 100%;

	cursor: pointer;
	transition: box-shadow 0.2s linear;
	transition-property: transform, box-shadow;

	border-radius: 16px;
	background-color: #FFF;
	box-shadow: 1px 5px 6px rgb(0 0 0 / 4%);
}

.desktopPartnerCard:hover {
	transition: all 0.2s ease;
	transform: translateY(-3px) translateZ(0);

	box-shadow: 1px 9px 4px 0 rgb(0 0 0 / 8%);
}

/*
.wrapperDesktop:hover .imgPartnerClose {
	opacity: 0.7;
	filter: opacity(.5);
	transition: opacity 0.2s ease;
}  */

/* .closed {
	filter: grayscale(80%) opacity(60%);
} */

.link::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;

	content: "";
}

.imgWrapper {
	position: relative;

	overflow: hidden;

	border-radius: 16px;
}

@media screen and (max-width: 1160px) {
	.imgWrapper {
		padding: clamp(82px, calc(7vw + 7px), 88px) 0;
	}
}

@media screen and (min-width: 1161px) and (max-width: 1301px) {
	.imgWrapper {
		padding: clamp(86px, calc(7vw + 5px), 96px) 0;
	}
}

@media screen and (min-width: 1302px) and (max-width: 1401px) {
	.imgWrapper {
		padding: clamp(86px, calc(7vw - 2px), 96px) 0;
	}
}

@media screen and (min-width: 1402px) {
	.imgWrapper {
		padding: clamp(86px, calc(6vw + 2px), 96px) 0;
	}
}

.imgPartnerClose {
	transition: filter 0.3s ease;

	border-radius: 16px;
	filter: opacity(0.5);
}

.desktopPartnerCard:hover .imgPartnerClose {
	border-radius: 16px;
	filter: opacity(0.8);
}

.imageContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	overflow: hidden;

	width: 100%;
	height: 100%;

	border-radius: 16px;
}

.closingPlaceNone {
	position: absolute;
	top: 0;
	left: 0;

	display: none;

	height: 28px;
	padding: 0 12px;

	letter-spacing: 0.03rem;

	color: #FFF;
	border-radius: 0;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 30px;
	background-color: #3F4246C2;

	font-size: 13px;
	font-weight: 500;
	line-height: 28px;
}

.closingPlace {
	display: unset;
}

.avgDeliveryTimePlace {
	position: absolute;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;

	height: 32px;

	padding: 6px 9px 6px 8px;

	letter-spacing: -0.01em;

	color: #FFF;

	border-radius: 16px 0 0 16px;
	background: rgb(69 68 68 / 85%);

	font-size: 14px;

	font-weight: 400;
	line-height: 18px;
}

.avgTimeIsNone {
	display: none;
}

.manSvg {
	margin-right: 4px;
}

.info {
	padding: 8px 16px;
}

.name {
	overflow: hidden;

	white-space: nowrap;

	text-overflow: ellipsis;
}

.subTitle {
	overflow: hidden;

	margin-top: 2px;

	white-space: nowrap;

	text-overflow: ellipsis;

	color: var(--color-gray-dark);

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
}

.meta {
	display: flex;

	height: 27px;
	margin-top: 16px;
}

.starSvg,
.clockSvg,
.bagSvg {
	fill: var(--color-primary);

	width: 18px;
	height: 18px;
}

.starSvg {
	width: 15px;
	height: 15px;
}

.raiting {
	display: flex;
	align-items: center;
}

.cost {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.deliveryTime {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.metaSubTextSpan {
	margin: 2px 0 0 4px;

	color: var(--color-gray-dark);

	font-size: 13px;
	font-weight: 600;
	line-height: 15px;
}
