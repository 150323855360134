.wrapper {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	overflow: hidden;

	width: 100%;
	height: 100%;

	border-radius: 0 0 24px 24px;
}

.map {
	overflow: hidden;

	width: 100%;
	height: 100%;
}

.container {
	position: absolute;
	z-index: 10005;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;

	pointer-events: none;
}

.infoPlate {
	position: absolute;
	z-index: 1000;
	top: 8px;
	left: 50%;

	display: flex;
	justify-content: center;

	width: 100%;

	transform: translate(-50%, 0%);

	opacity: 1;
	color: rgb(15 15 15);

	font-size: 22px;
	font-weight: 700;
	line-height: 26px;
}

.hiddenPlate {
	opacity: 0;
}

.markerContainer {
	position: absolute;
	z-index: 10010;
	top: calc(50% - 46px);
	left: calc(50% - 15px);

	width: 30px;
	height: 46px;
}

.marker {
	position: absolute;
	z-index: 10010;
	top: 0;
	left: 0;

	width: 30px;
	height: 46px;

	background-image: url("~src/assets/icons/marker.svg");
	background-repeat: no-repeat;
}

.zoomControl {
	position: absolute;
	z-index: 1000;
	top: 50%;
	right: 8px;

	overflow: hidden;

	transform: translate(0%, -50%);
	pointer-events: initial;

	border: 1px solid #D9D9D9;
	border-radius: 10px;
	background: #FFF;
}

.zoomButton {
	width: 48px;
	height: 48px;
	padding: 0;

	cursor: pointer;
	transition: all 0.2s ease;
	text-align: center;
	pointer-events: initial;

	font-size: 28px;
	line-height: 38px;
}

.zoomButton:hover {
	background-color: var(--color-fill);
}

.zoomButton:active {
	box-shadow: inset 0 2px 5px rgb(0 0 0 / 15%);
}
