.wrapper {
	width: 100%;
}

.title {
	margin: 16px 0 12px;

	text-align: center;
}

.rangeType {
	padding: 16px;
}

.rangeFlex {
	display: flex;
	justify-content: space-between;

	padding: 8px;

	border: 1px solid #EBEBEB;
	border-radius: 24px;
}

.buttonRangeType {
	padding: 0 32px;

	text-transform: uppercase;

	border-radius: 16px;

	font-size: 16px;
	font-weight: 500;
}

.timeList {
	display: flex;
	overflow-x: hidden;
	overflow-y: auto;
	flex: 0 1 auto;
	flex-flow: row wrap;

	max-height: 50vh;

	color: black;

	line-height: 30px;
}

.timeList::-webkit-scrollbar { width: 0; }

.alertText {
	margin: 40px auto;

	text-align: center;

	font-size: 20px;
	font-weight: 600;
}

.itemTimeList {
	display: flex;
	align-items: center;
	flex: 0 0 100%;
	justify-content: space-between;

	height: 44px;
	margin-top: 12px;
	padding: 0 12px;

	transition: background-color 0.2s ease;

	color: var(--color-black);
	border-radius: 16px;

	font-size: 16px;

	font-weight: 500;
}

.itemTimeList:active {
	background-color: var(--color-fill-hover);
}

.selectedItemList {
	background-color: var(--color-fill-hover);
}
