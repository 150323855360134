.popupWrapper {
	overflow-y: auto;

	width: 100vw;
	min-width: 300px;
	max-width: 380px;
	max-height: 360px;
}

/* .desktopPopupWrapper::-webkit-scrollbar { width: var(--scrollbar-width); } */

.filtersList {
	display: flex;
	overflow: hidden;
	flex-wrap: wrap;

	margin: 0;
	margin-top: -12px;
	padding: 0;

	list-style: none;
}

.itemList {
	padding-top: 12px;
	padding-right: 12px;
}

.itemListContent {
	height: 44px;
}
