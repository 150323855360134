.buttonSelectPayment {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;
}

.paymentIcon {
	flex: 0 0 auto;

	width: 45px;
	height: 30px;
	margin-right: 8px;
}

.paymentText {
	margin-right: auto;

	text-align: left;
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.arrowIcon {
	flex: 0 0 auto;

	width: 24px;
	height: 24px;

	margin-left: 4px;
}

.tooltip {
	max-width: 130px;
	margin-left: 8px;

	text-align: right;

	color: #757575;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}
