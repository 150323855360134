.container {
	position: relative;
}

.userCircle {
	width: 40px;
	height: 40px;

	text-transform: uppercase;

	color: #FFF;
	border-radius: 50%;
	background-color: #B3B3B3;

	font-size: 24px;
	font-weight: 700;
	line-height: 40px;
}

.unloggedIcon {
	margin-right: 8px;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;
	will-change: transform, opacity;

	background: transparent;
}

.popup {
	position: absolute;
	z-index: 1000;
	top: 0;
	right: 0;

	width: 375px;
	max-width: 375px;

	margin: 0;
	padding: 24px;

	list-style: none;

	text-align: left;

	border-radius: 16px;
	background: #FFF;
	box-shadow: 0 8px 24px rgb(0 0 0 / 16%);
}

.nameHolder {
	overflow: hidden;

	margin-bottom: 24px;
	padding: 0 16px;

	text-transform: capitalize;
	text-overflow: ellipsis;

	font-size: 26px;
	font-weight: 700;
	line-height: 30px;
}

.navList {
	margin: 0;
	padding: 0;

	list-style: none;
}

.listItem {
	position: relative;

	height: 48px;

	cursor: pointer;
	transition: background-color 0.3s ease;

	border-radius: 12px;
}

.listItem:not(:last-child) {
	margin-bottom: 8px;
}

.listItem:hover {
	background-color: #E6E6E6;
}

.listItem > * {
	display: flex;
	align-items: center;

	width: 100%;
	height: 100%;
	padding: 0 16px;
}

.itemName {
	flex-grow: 1;

	margin-left: 16px;
}
