.card {
	position: relative;

	display: flex;
	flex-direction: column;

	padding: 6px;

	transition: 0.3s ease;

	border-radius: 18px;
	background-color: var(--color-white);
	box-shadow: 0 2px 8px rgb(122 122 122 / 9%);
}

.cardCover {
	position: absolute;
	z-index: 3;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;

	pointer-events: painted;
}

.adaptiveCard {
	width: 100%;
}

.imageWrapper {
	position: relative;

	overflow: hidden;
	flex-shrink: 0;

	width: 100%;
	min-width: 132px;
	height: auto;
	min-height: 114px;

	border-radius: 18px;
	outline: none;
	background: rgb(221 221 221);
}

.adaptiveCard .imageWrapper {
	width: 100%;
}

.persentPlate {
	position: absolute;
	top: 4px;
	right: 4px;

	display: none;

	padding: 0 10px;

	letter-spacing: -0.01em;

	color: #FFF;
	border-radius: 12px;

	background: #FF6565;

	font-size: 16px;
	font-weight: 500;
	line-height: 28px;
	mix-blend-mode: normal;
}

.visiblePersentPate {
	display: unset;
}

.productInformation {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 158px;
	min-height: 100%;
	padding-top: 12px;
}

.price {
	display: flex;
	align-items: flex-end;

	height: 36px;

	color: var(--color-black);

	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
}

.hidden {
	display: none;
}

.oldCost {
	text-decoration: line-through;

	font-size: 14px;
	line-height: 16px;
}

.cost {
	font-size: 20px;
}

.redPrice {
	color: #F00;
}

.characteristic {
	margin-left: auto;

	color: var(--color-gray);

	font-size: 14px;
}

.name {
	margin-top: 6px;
	margin-bottom: auto;

	color: var(--color-black);

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}
