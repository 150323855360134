.counter {
	z-index: 10;

	display: flex;
	overflow: hidden;
	align-items: stretch;
	justify-content: space-between;

	width: 100%;
}

.button {
	width: 44px;
	padding: 0;
}

.count {
	align-self: center;

	min-width: 32px;

	text-align: center;

	color: var(--color-black);

	font-size: 18px;
	font-weight: 500;
}

.counterIcon {
	width: 20px;
	height: 20px;
	fill: var(--color-black);
}

.cartIcon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.addFirstProduct {
	width: 100%;

	white-space: nowrap;
	letter-spacing: -0.01em;

	color: var(--color-full-black);

	font-size: 14px;
	font-weight: 400;
}
