.profile {
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
}

.setings {
	width: 100%;
	margin-bottom: 16px;
}

.name {
	margin-bottom: 16px;
}

.buttonSaveName {
	width: 100%;

	letter-spacing: -0.01em;
	text-transform: uppercase;
}


.cards {
	/* width: 320px; */
}

.cardsList {
	overflow-y: auto;

	max-height: 216px;
}

.cardItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	padding: 8px 12px;

	transition: background-color 0.3s ease;

	border: 1px solid #B3B3B3;
	border-radius: 8px;
}

.cardItem:not(:last-child) {
	margin-bottom: 8px;
}

.cardItem:active {
	cursor: pointer;

	background-color: #E6E6E6;
}

.cardItemText {
	margin-right: auto;

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.cardItemIcon {
	flex: 0 0 auto;

	width: 45px;
	height: 30px;
	margin-right: 8px;
}

.checkIcon {
	display: flex;
	flex: 0 0 auto;

	width: 24px;
	height: 24px;
}

.checkIcon path {
    stroke: var(--color-primary); 
}
