.wrapperInput {
	position: relative;

	display: inline-block;

	width: 100%;
}

/* .successOverlay::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
} */

.Input {
	position: relative;

	display: inline-block;

	width: 100%;
	height: 48px;

	padding: 12px 14px;

	transition: all 0.2s ease-in-out;

	transition-property: color, background;

	border: none;
	border: 1px solid #D9D9D9;
	border-radius: 12px;
	outline: none;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.withLabelInput {
	padding: 10px 16px;
	padding-top: 24px;
}

.isPaddingRight {
	padding-right: 36px;
}

.Input:focus,
.Input:active {
	border: 1px #B3B3B3 solid;
}

.small {
	padding: 12px 48px;

	font-size: 14px;
	line-height: 19px;
}

.large {
	padding: 14px 42px;

	font-size: 18px;
	line-height: 25px;
}

.fill {
	color: var(--color-black);
	background-color: var(--color-fill);
}

.outline {
	border: 1px solid #D9D9D9;
	outline: none;
	background-color: transparent;
}

.outline:hover,
.outline:active {
	outline: none;
}

.text {
	border: 1px solid transparent;
}

.text:hover,
.text:focus,
.text:active {
	border: 1px solid transparent;
}

.clearInput {
	padding-right: 42px;
}

.buttonClear {
	position: absolute;
	top: 0;
	right: 0;

	width: 42px;
}

.deleteIcon {
	width: 100%;
}

.deleteIcon path {
	stroke: #757575;
}

.addoneAfter {
	position: absolute;
	top: 14px;
	right: 10px;
}

.feedBack {
	color: var(--color-primary);

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: 18px;
}

.successMessage {
	color: #096C16;
}

.errorMessage {
	color: var(--color-primary);
}

.success {
	border: 1px solid #096C16;
	background: #DDF4E0;
}

.error {
	border: 1px solid #FF4C30;
}

.label {
	position: absolute;
	top: 18px;
	left: 17px;

	overflow: hidden;

	width: calc(100% - 28px);
	margin-bottom: 0;

	cursor: text;
	transition:
		transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
		color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transform-origin: left top;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;

	color: #999;

	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	will-change: transform;
}

.hasValue {
	width: 100%;

	transform: translateY(-65%) scale(0.8);
}

.Input:focus .hasValue,
.Input:active .hasValue {
	width: 100%;

	transform: translateY(-65%) scale(0.8);
}
