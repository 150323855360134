.wrapper {
	display: flex;

	width: 100%;
	height: auto;

	/* overflow: hidden; */
}

.container {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
}

@media screen and (min-width: 520px) {
	.container {
		margin-left: calc(-24px / 2);
	}
}

@media screen and (min-width: 750px) {
	.container {
		margin-left: -24px;
	}
}

.partnerCard {
	width: 100%;
	margin-bottom: 24px;
}

@media screen and (min-width: 520px) {
	.partnerCard {
		width: calc(50% - 12px);
		margin-left: 12px;
	}
}

@media screen and (min-width: 750px) {
	.partnerCard {
		width: calc(50% - 24px);
		margin-left: 24px;
	}
}

@media screen and (min-width: 1024px) {
	.partnerCard {
		width: calc(33.33% - 24px);
		margin-left: 24px;
	}
}
