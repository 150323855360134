.wrapper {
	max-width: 1600px;
}

.container {
	overflow-x: auto;
	overflow-y: hidden;

	margin: 0 -16px;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none;
}

.container::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.gridContainer {
	display: flex;

	width: max-content;

	/* gap: 0  12px; */
	padding: 0 16px;
}

.itemContainer {
	padding-right: 12px;
	padding-bottom: 16px;
}

.itemContainer:last-child {
	padding-right: 0;
}

.partnerItem {
	position: relative;

	display: flex;
	align-items: center;

	width: max-content;
	height: 72px;

	padding: 8px 12px 8px 8px;

	transition: all 0.25s ease;

	border-radius: 16px;
	background: #FFF;
	box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
}

.pressed {
	transform: perspective(400px) scaleZ(1.5) translateZ(-15px);
}

.backgroundImg {
	position: relative;

	overflow: hidden;

	width: 48px;
	height: 48px;
	margin-right: 8px;

	border-radius: 50%;
}

.partnerName {
	max-width: 140px;

	text-align: left;

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.marginTop {
	margin-top: 24px;
}

.marginBottom {
	margin-bottom: 24px;
}

.slideImage {
	overflow: hidden;

	border-radius: 16px;
}
