.desktopContainerMap {
	display: flex;
	flex-direction: column;

	margin: 0;

	background: var(--color-white);
}

.addressDetails {
	display: flex;
	flex-direction: column;
}

.subDetails {
	display: grid;
	gap: 12px;

	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.preference {
	width: 100%;
	height: 96px;
	margin-top: 12px;

	resize: none;
}

.buttonsWrapper {
	display: flex;
	justify-content: space-between;

	margin-top: 24px;
}

.button {
	flex: 1 1 auto;
}

.button:first-child {
	margin-right: 16px;
}
