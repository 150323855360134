.tag {
	position: relative;

	display: flex;
	align-items: center;

	height: 100%;
	padding: 0 16px;

	cursor: pointer;
	transition: all 0.2s ease;
	white-space: nowrap;

	letter-spacing: 0.01em;

	color: var(--color-black);

	border: none;
	border-radius: 12px;
	background: #FFF;

	font-size: 17px;

	font-weight: 400;
}

/* BROWN COLOR */
.colorBrown {
	background: #EEE8DF;
}

.colorBrown:hover {
	background-color: #E7E2DC;
}

.colorBrown:active {
	background-color: #D2CCC3;
}

.active.colorBrown {
	background-color: #D2CCC3;
}

/* GRAY COLOR */
.colorGray {
	background: var(--color-gray-light);
}

.colorGray:hover {
	background-color: var(--color-gray-light-hover);
}

.colorGray:active {
	background-color: var(--color-gray-light-active);
}

.active.colorGray {
	background-color: var(--color-primary);
}

/* LIGTH COLOR */
.colorLight {
	background: var(--color-white);
}

.colorLight:hover {
	background-color: var(--color-gray-light-hover);
}

.colorLight:active {
	background-color: var(--color-gray-light-active);
}

.active.colorLight {
	background-color: var(--color-primary);
}

/* GOST COLOR */
.colorGost {
	background-color: transparent;
}

.colorGost:hover {
	color: rgb(0 0 0);
	background-color: #FCF8F8;
}

.colorGost:active {
	transition: all 0.3s ease;

	color: rgb(0 0 0) !important;
	background-color: #FFF !important;
}

.active.colorGost {
	color: var(--color-black);
	background-color: var(--color-white);
}

.active {
	color: var(--color-white);
}
