.button {
	z-index: 1;

	display: flex;
	overflow: hidden;

	padding: 0 14px;

	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transition-property: color, background;
	letter-spacing: -0.01em;

	border: none;
	background: none;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.button:disabled {
	cursor: default;
}

.wrapper {
	position: relative;
	z-index: 1;

	display: inline-flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

/* ================= BUTTON SIZES ================= */
.small {
	height: 36px;
	padding: 0 10px;

	border-radius: 16px;

	font-size: 14px;
	line-height: 18px;
}

.medium {
	height: 44px;

	border-radius: 10px;
}

.default {
	height: 48px;

	border-radius: 10px;
}

.large {
	height: 52px;

	border-radius: 12px;
}

/* ============================ */

/* ================= BUTTON TYPES  ================= */

/* PRIMARY */
.primary {
	color: var(--color-white);
	background-color: var(--color-primary);
}

.primary:disabled:not(.isLoading) {
	color: var(--color-white);
	background-color: var(--color-gray);
}

.primary:not(:disabled):hover {
	background-color: var(--color-primary-hover);
}

.primary:not(:disabled):active {
	background-color: var(--color-primary-active);
}

/* GRAY */
.gray {
	color: var(--color-black);
	background-color: var(--color-silver);
}

.gray:disabled:not(.isLoading) {
	background-color: var(--color-silver);
}

.gray:not(:disabled):hover {
	background-color: var(--color-silver-hover);
}

.gray:not(:disabled):active {
	background-color: var(--color-silver-active);
}

/* GOST */
.gost {
	color: var(--color-black);
	background-color: transparent;
}

.gost:disabled:not(.isLoading) {
	background-color: transparent;
}

.gost:not(:disabled):hover {
	background-color: var(--color-silver);
}

.gost:not(:disabled):active {
	background-color: var(--color-silver-active);
}

/* TEXT */
.text {
	padding: 0;

	color: var(--color-dull-black);
	background-color: transparent;
}

.text:disabled:not(.isLoading) {
	color: var(--color-dull-black);
}

.text:not(:disabled):hover,
.text:not(:disabled):active {
	color: var(--color-black);
}

.content {
	position: relative;

	display: inline-flex;
	align-items: center;
	flex: 1 1 auto;
	justify-content: center;

	width: 100%;
}

.contentHide {
	animation: hideContent 0.4s ease-in-out forwards;
}

@keyframes hideContent {
	0% {
		top: 0;

		opacity: 0;
	}

	100% {
		top: 100%;
	}
}

.contentVisible {
	top: 100%;

	animation: loadingContent 0.4s ease-in-out forwards;
}

@keyframes loadingContent {
	0% {
		top: 100%;

		opacity: 0;
	}

	100% {
		top: 0;

		opacity: 1;
	}
}

.loading {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.loadingVisible {
	animation: loadingVisible 0.4s ease-in-out forwards;
}

@keyframes loadingVisible {
	from {
		top: -100%;
	}

	to {
		top: 0;
	}
}

.loadingHide {
	animation: loadingHide 0.4s ease-in-out forwards;
}

@keyframes loadingHide {
	from {
		top: 0;
	}

	to {
		top: -100%;
	}
}
