.progressContainer {
	position: relative;

	display: flex;
	flex-direction: column;
}

.rowContainer {
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.warningTitle {
	color: var(--color-gray);
}

.text {
	letter-spacing: -0.025em;

	font-weight: 500;
}

.redText {
	color: #F9594A;
}

.greenText {
	color: #1EB62D;
}

.stagesContainer {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	width: 386px;
	margin-top: 16px;
	padding: 0 20px;
}

.itemStage {
	position: relative;

	display: flex;
	align-items: center;
	flex-direction: column;

	height: 74px;
}

.itemIcon {
	position: relative;

	width: 48px;
	height: 48px;
	padding: 12px;

	border-radius: 50%;
	background: #EBEBEB;
}

.itemIcon::after {
	position: absolute;
	top: 50%;
	right: calc(-50% - 6px);

	width: 4px;
	height: 4px;

	content: "";
	transform: translate(-50%, -50%);

	border-radius: 50%;
	background: #E6E6E6;
	box-shadow: 13px 0 0 0 #E6E6E6, -13px 0 0 0 #E6E6E6;
}

.itemIcon[data-last-child="true"]::after {
	content: unset;
}

.itemIcon svg * {
	stroke: rgb(117 117 117);
}

.stageName {
	position: absolute;
	bottom: 0;
	left: 50%;

	transform: translate(-50%, 0);
	text-align: center;
	white-space: nowrap;
	letter-spacing: -0.01em;

	color: #757575;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.itemStagesActive .itemIcon {
	background: var(--color-primary-light);
}

.itemStagesActive .itemIcon svg * {
	stroke: var(--color-primary);
}

.itemStagesActive .stageName {
	color: var(--color-primary);
}
