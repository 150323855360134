.wrapper {
	display: flex;
	overflow: hidden;
	overflow-y: scroll;
	flex-direction: column;

	width: 100vw;
	height: 100%;
	max-height: 100%;
	margin: 0 -24px;
	padding: 0 24px;
	padding-bottom: 114px;

	background: var(--color-white);
}

.wrapper::-webkit-scrollbar { width: 0; }

.header {
	position: relative;

	display: flex;
	flex: 0 0 auto;

	height: 260px;
	margin: 0 -24px;
	margin-bottom: 16px;
}

.img {
	width: 100vw;
	height: 260px;
}

.content {
	display: flex;
	flex: 0 1 auto;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
}

.infoItem {
	margin-bottom: 16px;

	font-size: 16px;
	font-weight: 500;
}

.bottomBar {
	position: absolute;

	z-index: 10;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	padding: 16px;

	border-top: solid 1px var(--color-border-light);
	background: #FFF;
}

.totalWrp {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.total {
	font-size: 18px;
	font-weight: 500;
}

.totalPrice {
	color: var(--color-primary);

	font-weight: 700;
}

.forOnePrice {
	margin-bottom: 4px;

	color: var(--color-black);

	font-size: 14px;
}

.buttonAddToCart {
	width: 100%;
	margin-top: 12px;
}
