.button {
	display: inline-flex;
	align-items: center;

	text-transform: uppercase;
}

.repeatIcon {
	width: 18px;
	height: 18px;
	margin-right: 10px;
}
