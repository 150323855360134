.catalog {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;

	margin-bottom: 32px;
	padding: 8px;

	border-radius: 16px;
	background: #F0F0F0; /* ececec | f9f5f5 | ebeeee */
}

.filters {
	display: flex;

	/* margin-right: auto; */
	overflow: hidden;
	flex: 0 1 100%;

	width: 100%;
}

.filtersList {
	display: flex;
	overflow: hidden;
	flex: 1 1 auto;
	flex-wrap: nowrap;

	margin: 0;
	padding: 0;

	list-style: none;
}

.itemList {
	padding-right: 8px;
}

.itemListContent {
	height: 44px;
}

.tagButton {
	flex: 0 0 auto;
}

.arrowIcon {
	margin-left: 4px;

	pointer-events: none;
	stroke: var(--color-gray-dark);
}

.sortButton {
	color: rgb(0 0 0);
}

.settingsSvg {
	margin-right: 4px;
}
