.partnerPlate {
	position: relative;

	display: inline-flex;
	flex-wrap: nowrap;

	width: auto;
	margin-right: 0;
}

.carouselContainer {
	margin-top: -6px;
}

.imageWrapper {
	position: relative;

	overflow: hidden;

	width: 80px;
	height: 80px;

	margin-right: 16px;

	border-radius: 16px;

	box-shadow: 3px 7px 20px rgb(122 122 122 / 9%);
}

.imgPartnerClose {
	transition: opacity 0.2s ease;

	opacity: 0.5;
}

.name {
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	font-size: 18px;
	line-height: 22px;
}

.subTitle {
	display: unset;
	overflow: hidden;

	margin-top: 2px;

	white-space: nowrap;

	text-overflow: ellipsis;

	color: var(--color-gray-dark);

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
}

.meta {
	display: flex;

	height: 18px;
	margin-top: 12px;
}

.starSvg,
.clockSvg,
.bagSvg {
	fill: var(--color-primary);
}

.starSvg {
	width: 15px;
	height: 15px;
}

.raiting {
	display: flex;
	align-items: center;
}

.cost {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.deliveryTime {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.metaSubTextSpan {
	margin: 2px 0 0 4px;

	color: var(--color-gray-dark);

	font-size: 13px;
	font-weight: 600;
	line-height: 18px;
}

.partnerPlateInfo {
	padding: 5px 0;
}
