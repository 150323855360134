.mobileCardLinkWrapper {
	position: relative;
}

.mobileCardLink {
	width: 0;
	height: 0;
}

.mobileCardLink::before {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	content: "";
	pointer-events: initial;
}
