.popupWrapper {
	overflow-y: auto;

	width: 100vw;
}

.popupWrapper::-webkit-scrollbar { width: 0; }

.desktopPopupWrapper {
	min-width: 300px;
	max-width: 380px;
}

.desktopPopupWrapper::-webkit-scrollbar { width: var(--scrollbar-width); }

.filtersList {
	display: flex;
	flex-wrap: wrap;
	gap: 12px 16px;
}

.addressItem {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 54px;
	padding: 8px;

	cursor: pointer;
	transition: 0.2s ease;

	border-radius: 16px;
}

.addressItem:hover {
	background-color: var(--color-fill-hover);
}

.address {
	display: flex;
	align-items: center;

	width: 100%;

	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.circle {
	width: 18px;
	height: 18px;
	margin-right: 10px;

	border: 1.5px solid #999;
	border-radius: 50%;
}

.selectCircle {
	position: relative;

	background-color: #FF4C30;
}

.selectCircle::after {
	position: absolute;
	top: -1px;
	left: calc(50% - 4px);

	width: 8px;
	height: 14px;

	content: "\2713";
	text-align: center;
	vertical-align: baseline;

	color: var(--color-white);

	font-size: 12px;
	font-weight: 900;
}

.addressStreet {
	display: inline-block;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	line-height: 20px;
}

.addressHouse {
	display: inline-block;
}

.bottomBar {
	z-index: 999;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 68px;

	/* padding: 0 16px;
  padding-bottom: 6px; */
	background-color: var(--color-white);
}

.buttonAddAddress {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;

	transition: background-color 0.3s ease;
	text-transform: uppercase;

	color: var(--color-black);

	background-color: var(--color-fill);

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.buttonAddAddress .text {
	margin-left: 8px;
}

.buttonAddAddress:hover,
.buttonAddAddress:active {
	transition: background-color 0.3s ease;

	background-color: var(--color-fill-hover);
}
