.addressSelectButton {
	display: flex;
	align-items: center;

	width: 100%;
}

.locationIcon {
	flex: 0 0 auto;

	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.addressWrapper {
	overflow: hidden;
	flex: 1 1 auto;

	text-align: left;
	letter-spacing: -0.01em;

	color: #212529;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.address {
	display: flex;
	align-items: center;
	flex: 1 1 auto;

	height: 22px;

	font-size: 16px;
}

.addressStreet {
	display: inline-block;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	line-height: 20px;
}

.addressHouse {
	display: inline-block;
}

.arrowIcon {
	flex: 0 0 auto;

	width: 20px;
	height: 20px;
	margin-left: 8px;

	pointer-events: none;
	stroke: var(--color-gray-dark);
}
