.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 44px;
}

.text {
	display: flex;

	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.crockeryIcon {
	margin-right: 10px;
}

.plusIcon {
	width: 18px;
	height: 18px;
	margin-right: 8px;
}

.crockeryIcon path {
	fill: black;
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;

	letter-spacing: -0.025rem;
	text-transform: uppercase;
}

.button:hover {
	transition: 0.2s ease-in-out;

	box-shadow: 0 2px 6px rgb(0 0 0 / 8%);
}

.button:active {
	background: #DFDFDF;
	box-shadow: 0 2px 4px rgb(0 0 0 / 6%);
}

.wrapper .cutleryCounter {
	margin-top: 0;
}
