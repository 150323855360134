.authCode {
	padding: 16px;

	border-radius: 12px;
	background-color: var(--color-gray-light);
}

.titleWrapper {
	display: flex;
	flex-direction: column;
}

.prompt {
	width: 80%;

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.promtOrdering {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	width: unset;
	margin-top: 12px;

	color: var(--color-gray-dark);

	line-height: 24px;
}

.phoneNubmer {
	margin-left: 8px;

	cursor: pointer;
	text-decoration: underline;
	text-decoration-color: #A6A7A6;
	text-decoration-style: dashed;
}

.phoneNubmer:hover {
	text-decoration: none;
}

.promtOrdering .phoneNubmer {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration-style: solid;

	line-height: 24px;
}

.editIcon {
	width: 24px;
	height: 24px;
	margin-top: -4px;
	margin-left: 4px;
}

.promtOrdering .promtText {
	margin-right: 6px;
}

.inputWrapper {
	position: relative;

	margin-top: 16px;
}

.inputWrapperOrdering {
	margin-top: 0;
}

.label {
	position: absolute;
	top: 12px;
	left: 0;

	overflow: hidden;

	width: calc(100% - 28px);
	margin-bottom: 0;

	cursor: text;
	transition:
		transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
		color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	transform-origin: left top;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;

	color: #999;

	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	will-change: transform;
}

.hasValue {
	width: 100%;

	transform: translateY(-65%) scale(0.9);
}

.input {
	width: 100%;
	height: 48px;
	padding: 0 16px;

	border: 1px solid var(--color-border);
	border-radius: 12px;
	background: transparent;

	font-size: 16px;
	font-weight: 500;
	line-height: 48px;
}

.input::placeholder {
	letter-spacing: -0.01em;

	color: #757575;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.input:focus-visible {
	border: 1px solid #666;
	outline: none;
}

.inputWrapper.inputWrapperInvalid .input {
	display: flex;
	flex-direction: column;

	border: 1px solid #FF4C30;
}

.inputDisabled::after {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	content: "";
}

.alertSvg {
	position: absolute;
	top: 14px;
	right: 10px;

	display: none;

	width: 18px;
	height: 17px;
}

.alertPrompt {
	color: var(--color-primary);

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: 18px;
}

.alertSvg,
.alertPrompt {
	display: none;
}

.inputWrapper.inputWrapperInvalid .alertSvg,
.inputWrapper.inputWrapperInvalid .alertPrompt {
	display: unset;
}

.inputOrdering {
	width: 110px;
	padding: 16px 0;
	padding-bottom: 10px;

	border: 1px solid transparent;

	font-size: 20px;
}

.inputOrdering:focus-visible {
	border: 1px solid transparent;
	outline: none;
}

.resendCode {
	margin-top: 16px;
}

.confirmBtn {
	width: 100%;
	margin-top: 16px;

	cursor: pointer;
	text-transform: uppercase;
}
