.content {
	padding-top: 24px;
}

.reviewText {
	width: 100%;
	height: 98px;
	margin-top: 12px;

	resize: none;

	color: var(--color-black);

	font-weight: 400;
}

.socLink {
	display: inline-flex;
	align-items: center;

	width: 100%;
	width: 160px;
	margin-top: 15px;

	color: var(--color-gray-dark);
}

.infoLink {
	margin-right: 10px;
}

.socLinkText {
	margin-left: 10px;

	transition: color 0.2s ease;
}

.socLinkText:hover {
	color: var(--color-gray);
}

.feedback {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	margin-bottom: 20px;
}

.feedback span {
	margin-bottom: 15px;

	font-size: 16px;
	font-weight: 400;
}

.positiveReview {
	margin-bottom: 20px;
}

.negativeReview {
	margin-bottom: 20px;
}

.bottomBarDesktop {
	margin-top: 20px;
}

.bottomBarMobile {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;

	padding: 16px;

	border-top: 1px solid var(--color-border-light);
}

.sendButton {
	width: 100%;

	text-transform: uppercase;
}

.modalFooterText {
	color: var(--color-gray-dark);
}

.blockedText {
	border: none;
	background-color: var(--color-gray-light);
}

.noPositiveFeedback,
.noNegativeFeedback {
	display: none;
}

.successFeedback {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 24px 0 0;
}

.successDesc {
	max-width: 300px;
	margin: 0 0 24px;

	text-align: center;

	color: #757575;

	font-size: 16px;
}

.buttonSuccess {
	padding: 15px 21px;
}

@media screen and (max-width: 415px) {
	.buttonSuccess {
		width: 100%;
	}
}
