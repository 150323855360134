.repeatCode {
	cursor: pointer;
	text-align: center;
	letter-spacing: -0.01em;

	color: var(--color-primary);

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	line-height: 22px;
}

.repeatCodeAfter {
	cursor: default;

	color: var(--color-gray-dark);
}

.repeatTextNormal {
	display: inline-block;

	text-align: left;

	font-weight: 400;
}

.textUnderline {
	text-decoration: underline;
	text-decoration-style: solid;
}

.textUnderline:hover {
	text-decoration: none;
}
