.buttonDelivery {
	position: relative;

	display: flex;
	align-items: center;

	margin-right: 12px;
	padding: 6px 10px;
}

.icon {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	fill: var(--color-primary);
}

.buttonText {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	letter-spacing: -0.01em;
	text-transform: uppercase;

	font-weight: 500;
}

.supText {
	color: var(--color-black);

	font-size: 14px;
	line-height: 16px;
}

.subText {
	color: var(--color-gray-dark);

	font-size: 10px;
	line-height: 12px;
	text-decoration-line: underline;
}

.feedback {
	text-transform: lowercase;

	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
}

.ratingIcon {
	min-width: 20px;
	max-width: 20px;
}

.ratingHeader {
	display: flex;
	flex-direction: column;

	padding-bottom: 16px;

	border-bottom: 1px solid #D9D9D9;
	gap: 16px;
}

.ratingFeedbackTop {
	display: flex;
	justify-content: space-between;
}

.ratingHeaderItems {
	display: flex;
	align-items: center;
	gap: 16px;
}

.ratingHeaderItem {
	display: flex;
	flex: 0 0 calc(50% - 8px);
	flex-direction: column;
	gap: 8px;
}

.ratingHeaderItemTitle {
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.ratingHeaderSubItem {
	display: flex;
	align-items: center;

	width: min-content;
	padding: 6px;
	gap: 4px;
}

.ratingIconTitle {
	width: 28px;
	height: 28px;
}

.ratingHeaderValue {
	color: var(--color-black);

	font-size: 22px;
	font-weight: 500;
	line-height: 28px;
}

.ratingScroll {
	position: relative;

	overflow: hidden;

	min-height: 200px;
	padding: 0;
}

.ratingBody {
	overflow-y: auto;

	max-height: calc(85vh - 220px);
	margin: 0;
	padding: 0;

	list-style: none;
}

.ratingBody::-webkit-scrollbar {
	width: 0;
}

.ratingFeedback {
	padding: 16px 0;

	border-bottom: 1px solid #D9D9D9;
}

.ratingFeedbackDate {
	letter-spacing: -0.01em;

	color: #212529;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.ratingFeedbackDate span {
	margin-right: 8px;
}

.ratingStars {
	display: flex;
	align-items: center;
	gap: 2px;
}

.ratingFeedbackFilled {
	fill: #FF4C30;
}

.ratingFeedbackItems,
.ratingFeedback {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.ratingFeedbackItem {
	display: flex;
	align-items: center;
	gap: 8px;
}

.ratingIcon {
	flex: 0 0 auto;
}

.ratingFeedbackText {
	letter-spacing: -0.01em;

	color: #212529;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}
