.partnerPlate {
	position: relative;

	display: inline-flex;
	flex-wrap: nowrap;

	width: 100%;
	margin-right: auto;
}

.fakeLink {
	position: absolute;
	top: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 26px;
	height: 26px;

	border-radius: 8px;
	background: #EBEBEB;
}

.fakeLink svg {
	width: 22px;
	height: 22px;
}

.carouselContainer {
	margin-top: -6px;
}

.imageWrapper {
	position: relative;

	overflow: hidden;

	width: 42px;
	height: 42px;

	margin-right: 16px;

	border-radius: 8px;

	box-shadow: 3px 7px 20px rgb(122 122 122 / 9%);
}

.smallSize .imageWrapper {
	width: 42px;
	height: 42px;

	border-radius: 8px;
}

.imgPartnerClose {
	transition: opacity 0.2s ease;

	opacity: 0.5;
}

.name {
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	font-size: 16px;
	line-height: 20px;
}

.subTitle {
	display: none;
}

.meta {
	display: flex;

	height: 18px;
	margin-top: 4px;
}

.starSvg,
.clockSvg,
.bagSvg {
	fill: var(--color-primary);
}

.starSvg {
	width: 15px;
	height: 15px;
}

.raiting {
	display: flex;
	align-items: center;
}

.cost {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.deliveryTime {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.metaSubTextSpan {
	margin: 2px 0 0 4px;

	color: var(--color-gray-dark);

	font-size: 13px;
	font-weight: 600;
	line-height: 18px;
}
