.tag {
	position: relative;

	display: flex;
	align-items: center;

	padding: 0 16px;

	cursor: pointer;
	transition: all 0.15s ease;
	white-space: nowrap;
	letter-spacing: 0.01em;

	color: var(--color-black);

	border: none;
	border-radius: 12px;
	background: #FFF;

	font-size: 14px;

	font-weight: 400;

	line-height: 40px;
}

.colorGray {
	background: var(--color-fill);
}

.colorLight {
	background: var(--color-white);
}

.active {
	color: var(--color-white);
	background-color: var(--color-primary);
}

.active:hover {
	background-color: var(--color-primary);
}

.buttonPressed {
	/* transform: perspective(400px) translateZ(-50px); */
	transition: all 0.15s ease;
	transform: perspective(400px) scaleZ(1.5) translateZ(-10px);

	background-color: var(--color-gray-light-hover);
}
