.slider {
	position: relative;

	z-index: 0;

	overflow: hidden;

	margin: 0 -16px;

	padding: 16px 0 24px;
}

.slider::after,
.slider::before {
	display: none;
}

.sliderDesktop {
	padding: 32px 0 56px;
}

.safeArea {
	/* overflow: visible !important; */

	padding: 0 16px !important;
}

@media screen and (min-width: 1280px) {
	.safeArea {
		padding: 0 !important;
	}

	.slider {
		overflow: unset;

		margin: 0;
	}

	.slider::after,
	.slider::before {
		position: absolute;
		z-index: 900;
		top: 0;
		right: 2px;

		display: block;

		/* width: 100vw; */
		height: 100%;

		content: "";
		transform: translateX(calc(100% + 15px)); /* 18px is gap size */
	}

	.slider::before {
		right: unset;

		left: 2px;

		transform: translateX(calc(-100% - 15px)); /* 18px is gap size */
	}

	.sliderWhite::after,
	.sliderWhite::before {
		background-color: #FFF;
	}

	.sliderLite::after,
	.sliderLite::before {
		background-color: #F8F8F8;
	}
}

.prevButton,
.nextButton {
	position: absolute;
	z-index: 990;
	top: calc(50% - 22px);

	display: flex;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;

	margin: 0;
	padding: 2px;

	cursor: pointer;

	transform: translate(-100%) translateY(-50%);

	border: none;
	border-radius: 50%;
	background: #FFF;
	box-shadow: 3px 2px 15px 5px rgb(0 0 0 / 6%);
}

.prevButton {
	left: 25px;

	transform: translate(calc(-100%)) translateY(-50%)  rotate(180deg);

	box-shadow: 3px -2px 15px 5px rgb(0 0 0 / 6%);
}

.nextButton {
	right: 25px;

	transform: translate(100%) translateY(-50%);

	box-shadow: 3px -2px 15px 5px rgb(0 0 0 / 6%);
}

.prevButton:disabled,
.nextButton:disabled {
	animation: disabledButton 1s ease-in-out forwards;
}

@keyframes disabledButton {
	from {
		pointer-events: auto;

		opacity: 1;
	}

	to {
		pointer-events: none;

		opacity: 0;
	}
}

.prevButton svg,
.nextButton svg {
	transition: stroke 0.4s ease;
}

.prevButton:hover svg,
.nextButton:hover svg {
	stroke: var(--color-primary);
}

.hiddenButton {
	display: none;
}
