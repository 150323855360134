.popupWrapper {
	overflow-y: auto;

	width: 100%;
	max-height: 360px;
}

.filtersList {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
}
