.tags {
	width: 100%;
	margin: 0;
	padding: 24px 0;
	padding-bottom: 32px;
}

.gridTag {
	display: flex;
	flex-wrap: nowrap;

	width: 100%;
	gap: 0  32px;

	padding: 0;
}
