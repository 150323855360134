.wrapper {
	overflow: hidden;
	overflow-y: scroll;

	width: calc(100% + 32px);
	height: 100vh;
	margin: 0 -16px;
}

.header {
	display: flex;
	align-items: center;
	flex: 0 0 auto;

	width: 100%;
	height: var(--mobile-header-height);

	background-color: var(--color-white);
	box-shadow: 3px 7px 20px rgb(122 122 122 / 9%);
}

.buttonClear {
	flex: 0 0 auto;

	width: 68px;
	height: 100%;
	padding-right: 16px;
	padding-left: 12px;
}

.clearIcon {
	width: 40px;
	height: 40px;
	padding: 8px;

	border-radius: 10px;
	background-color: var(--color-fill);
}

.title {
	flex: 1 1 auto;
}

.separateLine {
	width: 100%;
	height: 1px;
	margin: 16px 0;

	background-color: #EBEBEB;
}

.content {
	position: relative;

	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
}

.image {
	overflow: hidden;
	flex: 0 0 auto;

	width: 48px;
	height: 48px;
	margin-right: 12px;

	border-radius: 50%;
}

.cartBody {
	flex: 1 1 auto;

	padding: 16px;
	padding-bottom: 36px;
}

.greenText {
	display: flex;
	align-items: center;

	padding: 6px 8px;

	letter-spacing: -0.01em;

	color: #4A952C;
	border-radius: 8px;
	background: rgb(74 149 44 / 10%);

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.homeIcon {
	flex-grow: 0 0 auto;

	width: 24px;
	height: 24px;
	margin-right: 8px;
	margin-left: -2px;
}

.paymentItemInfo {
	display: flex;
	justify-content: space-between;

	margin: 0;
	margin-bottom: 10px;

	letter-spacing: -0.01em;

	color: var(--color-gray-dark);

	font-size: 16px;
	line-height: 20px;
}

.itemSumm {
	color: var(--color-black);

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.underText {
	margin-right: 8px;

	text-decoration: line-through;
}

.redText {
	color: var(--color-primary);
}

.totalSumm {
	color: var(--color-black);
}

.totalSumm .itemSumm {
	color: var(--color-primary);
}

.emptyContent {
	height: calc(100% - var(--mobile-header-height));
}

.emptyBody {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;

	padding: 16px;
}

.imageWrapper {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	flex-direction: column;
	justify-content: center;

	width: 65vw;
	margin: auto;
}

.emptyText {
	margin: 0;

	text-align: center;

	color: #999;

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.buttonsWrapper {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	padding: 16px;
	padding-bottom: 24px;
	gap: 12px;
}

.actionButton {
	flex: 1 1 45%;

	min-width: 240px;

	text-transform: uppercase;
}
