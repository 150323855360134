.header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;

	transition: box-shadow, border-bottom 0.3s ease;

	border-bottom: 1px solid rgb(0 0 0 / 10%);

	background-color: var(--color-white);
}

.isScrollHeader {
	transition: box-shadow, border-bottom 0.3s ease;

	border-color: transparent;
	box-shadow: 0 7px 25px rgb(117 116 112 / 20%);
}

.logoWrapper {
	display: none;
}

.search a {
	display: inherit;
}

.searchMobile {
	display: flex;
	align-items: center;
	flex: 0 0 auto;

	width: 68px;
	height: 100%;
	padding-right: 12px;
	padding-left: 16px;
}

.searchIconLink {
	width: 40px;
	height: 40px;
	padding: 6px;

	opacity: 0.7;
	border-radius: 10px;
	background-color: var(--color-fill);
}

.searchTablet {
	display: none;
}

.citySelectWrapper {
	flex: 0 1 auto;
}

@media screen and (min-width: 750px) {
	.logoWrapper {
		display: unset;
		order: 0;

		width: 68px;
		height: 100%;
		padding-right: 12px;
		padding-left: 16px;

		background-image: url("~src/assets/svg/logo_icon.svg");
		background-repeat: no-repeat;
		background-position: 16px 50%;
		background-size: auto 30px;
	}

	.searchMobile {
		display: none;
	}

	.searchTablet {
		display: unset;

		width: 100%;
		max-width: 335px;
		margin-right: auto;
	}

	.wrapperSearch {
		position: relative;

		display: flex;
		align-items: center;

		height: 40px;
	}

	.searchIcon {
		position: absolute;

		width: 36px;
		height: 30px;

		opacity: 0.5;
	}

	.field {
		overflow: hidden;

		width: 100%;
		padding: 0 30px 0 36px;

		white-space: nowrap;

		text-overflow: ellipsis;

		border: 1px rgb(0 0 0 / 0%) solid;
		border-radius: 12px;
		outline: none;
		background-color: var(--color-fill);

		font-size: 14px;
		line-height: 38px;
	}
}
