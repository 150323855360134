.wrapper {
	width: 320px;
}

.payment {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	padding: 8px 12px;

	transition: background-color 0.3s ease;

	border-radius: 12px;
}

.payment:not(:last-child) {
	margin-bottom: 8px;
}

.payment:hover {
	cursor: pointer;

	background-color: #E6E6E6;
}

.paymentText {
	margin-right: auto;

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.paymentIcon {
	flex: 0 0 auto;

	width: 45px;
	height: 30px;
	margin-right: 8px;
}

.checkIcon {
	display: none;
	flex: 0 0 auto;

	width: 24px;
	height: 24px;
}

.active .checkIcon {
	display: unset;
}
