.switchContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.switcher {
	display: grid;
	align-items: center;

	flex-shrink: 0;

	padding: 2px;
	appearance: none;

	inline-size: 32px;
	block-size: 32px;
}

.switcher[type="checkbox"] {
	position: absolute;

	width: 30px;
	height: 30px;
}
