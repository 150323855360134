.container {
	display: grid;
	gap: 32px 32px;

	margin-bottom: 100px;

	grid-template-columns: repeat(3, 1fr);
}

@media screen and (min-width: 1402px) {
	.container {
		grid-template-columns: repeat(4, 1fr);
	}
}
