.mobileBanner {
	position: relative;

	display: flex;
	overflow: hidden;
	flex-direction: column;

	width: 133px;
	height: 133px;

	transition: all 0.25s ease;

	border-radius: 16px;
	background: #0B939C;
	box-shadow: 0 4px 10px rgb(0 0 0 / 2%);
}

.pressed {
	transform: perspective(400px) scaleZ(1.5) translateZ(-15px);
}

.backgroundImg {
	width: 133px;
	height: 133px;
}

.mobileTitle,
.mobileText,
.mobileLogo {
	z-index: 1;
}

.promoCode {
	position: absolute;
	z-index: 2;
	bottom: 14px;
	left: 14px;

	padding: 6px 8px 5px;

	cursor: pointer;
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: #F9594C;
	border-radius: 4px;
	background: #FFF;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.backPromoCodeText {
	overflow: hidden;

	width: calc(100% - 28px);

	letter-spacing: -0.01em;
	text-transform: uppercase;
	text-overflow: ellipsis;

	color: var(--color-black);

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.backPromoCode {
	position: absolute;
	bottom: 14px;
	left: 14px;

	display: flex;

	width: calc(100% - 28px);
	height: 26px;
	padding: 4px 8px;

	cursor: pointer;

	border-radius: 4px;
	background: #FFF;
	gap: 10px;
}

.backText {
	overflow-y: auto;

	max-height: calc(100% - 34px);

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.backText::-webkit-scrollbar-track {
	border-radius: 5px;
}

.backTextCopied {
	position: absolute;
	top: 100%;
	left: calc(50% - 8px);

	visibility: hidden;

	padding: 4px 8px;

	cursor: default;
	user-select: none;
	transition: all 0.3s linear;
	transform: translateX(-50%);

	opacity: 0;
	color: var(--color-gray-dark);
	border-radius: 4px;
	background: #FFF;
}

.backTextCopied.isCopied {
	top: 50%;

	visibility: visible;

	opacity: 1;
}

.backTextFull {
	max-height: 100%;
}

.mobileTitle {
	position: absolute;
	top: 14px;
	left: 14px;

	letter-spacing: -0.01em;

	color: #FFF;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.mobileText {
	letter-spacing: -0.01em;

	color: #FFF;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.mobileLogo {
	position: absolute;
	bottom: 14px;
	left: 14px;

	width: 75px;
	height: 29px;

	border-radius: 5px;
}

.mobileModal {
	/* transition: all .3s linear; */
	z-index: 1;

	display: flex;
	flex-direction: column;

	/* position: fixed; */

	/* left: 0; */

	/* right: 0; */

	/* bottom: -100%; */
	height: auto;
	min-height: auto;
	max-height: none;
	padding: 20px 16px;

	border-radius: 24px 24px 0 0;
	background: #FFF;
	box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
}

.mobileModal.open {
	bottom: 0;
}

.mobileBackTop {
	display: flex;
	justify-content: space-between;

	margin-bottom: 24px;
}

.mobileBackTitle {
	color: var(--color-black);

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.mobileClose {
	display: flex;
}

.mobileBackText {
	letter-spacing: -0.01em;

	color: #4D4D4D;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.mobileBackText.textMargin {
	margin-bottom: 24px;
}

.mobileCopied {
	max-height: 0;
	margin-bottom: 0;

	transition: all 0.2s linear;
	text-align: center;
	letter-spacing: -0.01em;

	color: #999;

	font-size: 0;
	font-weight: 400;
	line-height: 18px;
}

.mobileCopied.isCopied {
	max-height: 18px;
	margin-bottom: 24px;

	font-size: 14px;
}

.mobileBottom {
	display: flex;
	align-items: center;
	gap: 12px;
}

.mobilePromoCode,
.mobileCopyBtn {
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobilePromoCode {
	width: calc(100% - 58px);
	height: 48px;

	letter-spacing: -0.01em;
	text-transform: uppercase;

	border-radius: 12px;
	background: #F5F5F5;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.mobileCopyBtn {
	width: 46px;
	height: 46px;

	border-radius: 8px;
	background: #FEEBEA;
}
