.buttonTimePicker {
	display: flex;
	align-items: center;

	cursor: pointer;

	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
}

.addone {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
}

.addoneBefore {
	margin-right: 8px;
}

.addoneAfter {
	margin-left: 8px;
}

.time {
	letter-spacing: -0.01em;

	color: var(--color-black);
}
