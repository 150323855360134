.authStage {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;

	/* align-items: center; */
	width: 100%;
}

.enter {
	opacity: 0;
}

.exit {
	opacity: 1;
}

.enterActive {
	opacity: 1;
}

.exitActive {
	opacity: 0;
}

.enterActive,
.exitActive {
	transition: opacity 300ms;
}
