.plate {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;

	width: 100%;
	min-height: 54px;
	margin-bottom: 8px;
	padding: 0 20px;

	border-radius: 12px;
	background: rgb(255 76 48 / 8%);
}

.icon {
	flex-shrink: 0;

	width: 32px;
	height: 32px;
	margin-right: 8px;
}

.text {
	letter-spacing: -0.01em;

	color: #FF4C30;

	font-size: 17px;
	font-weight: 400;
	line-height: 20px;
}

.plateMobile {
	min-height: 46px;
	padding: 0 12px;
}

.plateMobile .icon {
	width: 26px;
	height: 26px;
}

.plateMobile .text {
	font-size: 14px;
	line-height: 18px;
}
