.addressButton {
	display: flex;
	overflow: hidden;
	flex-direction: column;

	min-width: 1px;
	height: 40px;
}

@media screen and (min-width: 750px) {
	.addressButton {
		align-items: flex-start;

		width: auto;
		max-width: 300px;
	}
}

.container {
	overflow: hidden;

	width: 100%;
}

.cityName {
	font-size: 12px;
	line-height: 10px;
}

.address {
	display: flex;
	align-items: center;

	width: 100%;
	height: 22px;

	font-size: 14px;
}

@media screen and (max-width: 380px) {
	.address {
		font-size: 13px;
	}
}

.addressStreet {
	display: inline-block;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	line-height: 20px;
}

.addressHouse {
	display: inline-block;
}

.arrowIcon {
	margin-left: 4px;

	pointer-events: none;
	stroke: var(--color-gray-dark);
}

.emptyAddress {
	align-items: center;
	flex-direction: row;

	padding: 0 12px;
}

.locationIcon {
	width: 20px;
	height: 20px;
	margin-top: -2px;
	margin-right: 6px;
}

.emptyText {
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	font-size: 14px;
}
