.desktopPopupWrapper {
	width: unset;
	max-width: 474px;
	max-height: 306px;
}

.addressList {
	overflow-y: auto;

	max-height: 216px;
}
.addressList::-webkit-scrollbar { width: 0; }

.addressItem {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: 54px;
	padding: 8px;

	cursor: pointer;
	transition: 0.2s ease;

	border-radius: 16px;
}

.addressItem:hover {
	background-color: var(--color-fill-hover);
}

.address {
	display: flex;
	align-items: center;

	width: 100%;

	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.circle {
	flex: 0 0 auto;

	width: 18px;
	height: 18px;
	margin-right: 10px;

	border: 1.5px solid #999;
	border-radius: 50%;
}

.selectCircle {
	position: relative;

	background-color: #FF4C30;
}

.selectCircle::after {
	position: absolute;
	top: -2px;
	left: calc(50% - 4px);

	width: 8px;
	height: 14px;

	content: "\2713";
	text-align: center;
	vertical-align: baseline;

	color: var(--color-white);

	font-size: 12px;
	font-weight: 900;
}

.addressStreet {
	display: inline-block;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	line-height: 20px;
}

.addressHouse {
	display: inline-block;
}

.bottomBar {
	z-index: 999;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	padding-top: 10px;

	background-color: var(--color-white);
}

.buttonAddAddress {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;

	text-transform: uppercase;

	font-weight: 500;
}

.buttonAddAddress .text {
	margin-left: 8px;
}
