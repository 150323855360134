.wrapper {
	display: flex;

	width: 100%;
	margin-bottom: 16px;

	cursor: pointer;
}

/* h5 {
	font-size: 16px;
	font-weight: 500;
} */

.title {
	margin-left: 3px;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}

.price {
	margin-left: 10px;

	white-space: nowrap;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	line-height: 22px;
}
