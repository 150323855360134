.buttonSelectPayment {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;
}

.paymentIcon {
	flex: 0 0 auto;

	width: 30px;
	height: 20px;
	margin-right: 8px;
}

.paymentText {
	flex: 1 1 auto;

	text-align: left;
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.arrowIcon {
	flex: 0 0 auto;

	width: 20px;
	height: 20px;
	margin-left: 8px;
}
