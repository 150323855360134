.wrapper {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	overflow: hidden;

	width: 100%;
	height: 100%;

	background-color: var(--color-white);
}

.map {
	position: relative;
	z-index: 101;

	width: 100%;
	height: 100%;

	opacity: 0.8;
	filter: grayscale(0.3);
}

.locationContainer {
	position: absolute;
	z-index: 102;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;

	pointer-events: none;
}

.loactionPlate {
	position: absolute;
	z-index: 10010;
	top: 10%;

	width: 100%;
	margin-top: max(calc(20px + var(--safe-area-inset-top, 0px)), 0px);
	padding: 0 24px;

	transition: all 0.2s linear;

	opacity: 1;
	color: #21201F;
}

.infoPlate {
	position: absolute;
	z-index: 10010;
	top: 34%;
	left: 50%;

	width: auto;
	padding: 0 12px;

	transition: all 0.2s linear;
	transform: translate(-50%, 0);
	white-space: nowrap;

	opacity: 1;
	color: rgb(15 15 15);
	border-radius: 20px;
	background-color: #F7F7F3BE;
	box-shadow: 0 0 10px 3px rgb(68 65 65 / 10%);

	font-size: 16px;
	font-weight: 500;
	line-height: 36px;
}

.hiddenPlate {
	transition: all 0.2s linear;

	opacity: 0;
}

@media screen and (max-width: 380px) {
	.infoPlate {
		top: 30%;
	}
}

.markerContainer {
	position: absolute;
	z-index: 10010;
	top: calc(50% - 46px);
	left: calc(50% - 15px);

	width: 30px;
	height: 46px;
}

.markerContainer::before {
	position: absolute;
	bottom: -1px;
	left: calc(50% - 2px);

	width: 4px;
	height: 3px;

	content: "";

	border-radius: 50%;
	background-color: var(--color-gray-dark);
}

.marker {
	position: absolute;
	z-index: 10010;
	top: 0;
	left: 0;

	width: 30px;
	height: 46px;

	background-image: url("~src/assets/icons/marker.svg");
	background-repeat: no-repeat;
}

.markerSearching {
	animation: bounce 1.8s linear infinite;
}

@keyframes bounce {
	0% { transform: translateY(-10px); }
	50% { transform: translateY(-20px); }
	100% { transform: translateY(-10px); }
}

.markerShadow {
	position: absolute;

	/* transition: all 1.2s linear; */
	z-index: 10010;
	bottom: -5px;
	left: calc(50% - 10px);

	width: 20px;
	height: 10px;

	border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
	background-color: rgb(184 184 184 / 50%);
}

.markerShadowSearching {
	animation: bounce2 1.8s linear infinite;

	box-shadow: 0 0 4px 2px rgb(68 65 65 / 15%);
}

@keyframes bounce2 {
	0% {
		bottom: -6px;
		left: calc(50% - 12px);

		width: 24px;
		height: 12px;

		background-color: rgb(184 184 184 / 38%);
	}

	50% {
		bottom: -7px;
		left: calc(50% - 16px);

		width: 32px;
		height: 14px;

		background-color: rgb(184 184 184 / 28%);
	}

	100% {
		bottom: -6px;
		left: calc(50% - 12px);

		width: 24px;
		height: 12px;

		background-color: rgb(184 184 184 / 38%);
	}
}

.zoomControl {
	position: absolute;
	z-index: 1000;
	right: 16px;
	bottom: 50%;

	overflow: hidden;

	transition: all 0.2s linear;
	pointer-events: initial;

	opacity: 0.9;
	border: 1px solid #D9D9D9;
	border-radius: 10px;
	background: #FFF;
}

.zoomButton {
	width: 42px;
	height: 44px;
	padding: 0;

	cursor: pointer;
	transition: all 0.2s ease;
	text-align: center;

	font-size: 28px;
	line-height: 38px;
}

.zoomButton:active {
	background-color: var(--color-fill);
	box-shadow: inset 0 2px 5px rgb(0 0 0 / 15%);
}

.bottomBar {
	position: absolute;
	z-index: 1000;
	right: 0;
	bottom: 24px;
	left: 0;

	width: 100%;
	padding: 0 16px;
	padding-bottom: 24px;
	padding-bottom: max(calc(0px + var(--safe-area-inset-bottom, 0px)), 0px);

	transition: all 0.2s linear;
	pointer-events: initial;

	opacity: 1;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.saveButton {
	width: 100%;

	text-transform: uppercase;
	pointer-events: inherit;
}

.detailsButton {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	margin-bottom: 16px;

	text-transform: uppercase;
	pointer-events: inherit;

	opacity: 0.85;
	border: 1px solid #D9D9D9;
	background: #FFF;
}

.arrowRightIcon {
	position: absolute;
	top: 50%;
	right: 14px;

	transform: translate(0, -50%);
}

.hiddenControl {
	transition: all 0.2s linear;
	pointer-events: none;

	opacity: 0;
}
