.buttonTimePicker {
	display: flex;
	align-items: center;

	width: 100%;
}

.addone {
	display: flex;
	align-items: center;

	flex: 0 0 auto;
	justify-content: center;
}

.addoneBefore {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.addoneAfter {
	width: 20px;
	height: 20px;
	margin-left: 8px;
}

.time {
	flex: 1 1 auto;

	text-align: left;
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.lightText {
	margin-left: 8px;

	letter-spacing: -0.01em;

	color: #706B6B;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}
