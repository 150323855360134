.buttonSelectPayment {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;
}

.paymentIcon {
	flex: 0 0 auto;

	width: 30px;
	height: 20px;
	margin-right: 8px;
}

.paymentText {
	flex: 1 1 auto;

	text-align: left;

	white-space: pre-line;
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.arrowIcon {
	flex: 0 0 auto;

	width: 20px;
	height: 20px;
	margin-left: 4px;
}

.lightText {
	margin-left: 8px;

	letter-spacing: -0.01em;

	color: #706B6B;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.lightText::before {
	content: "Способ оплаты";
}

@media screen and (min-width: 360px) {
	.lightText::before {
		content: "Другой способ оплаты";
	}
}

.tooltip {
	max-width: 130px;
	margin-left: 8px;

	text-align: right;

	color: #757575;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}
