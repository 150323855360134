.product {
	position: relative;

	display: flex;
	align-items: flex-start;
	align-self: center;
	justify-content: center;

	width: 100%;
	min-height: 68px;
	margin-bottom: 24px;
	padding-left: 84px;
}

.imageContainer {
	position: absolute;
	top: 0;
	left: 0;

	overflow: hidden;

	width: 68px;
	height: 68px;

	border-radius: 8px;
	background-color: #F2F2F2;
	box-shadow: 2px 2px 5px rgb(33 32 31 / 10%);
}

.placeholderIcon {
	width: 100%;
	height: 100%;
}

.productOptions {
	display: flex;
	flex-direction: column;

	width: 100%;
}

.title {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.productCost {
	flex: 0 0 auto;

	white-space: nowrap;

	color: var(--color-dull-black);
}

.additivesWrapper {
	margin: 8px 0;
}

.additiveItem {
	margin: 0;
	padding: 0;

	vertical-align: baseline;

	color: #B0B0B0;
	border: 0;

	font: inherit;
	font-size: 14px;
	line-height: 1em;
}

.price {
	display: flex;
	align-items: center;

	margin-top: 10px;

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.productQuantity {
	margin-right: auto;
}

.oldCost {
	text-decoration: line-through;
	letter-spacing: -0.01em;

	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.redColor {
	color: var(--color-primary);
}
