.wrapper {
	display: flex;
	flex-direction: column;

	width: 100%;

	background: var(--color-white);
}

.buttonsWrapper {
	display: flex;

	margin-top: 16px;
	gap: 16px;
}

.promocodeButton {
	width: 50%;

	letter-spacing: -0.01em;
	text-transform: uppercase;

	font-weight: 600;
}
