.wrapper {
	position: relative;

	display: flex;
	align-items: center;

	width: 100%;
	height: 40px;
}

.field {
	width: 100%;
	padding: 0 30px 0 36px;

	text-overflow: ellipsis;

	border: 1px rgb(0 0 0 / 0%) solid;
	border-radius: 12px;
	outline: none;
	background-color: var(--color-fill);

	font-size: 14px;
	line-height: 38px;
}

.button {
	position: absolute;
	z-index: 10000;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 36px;
	height: 40px;
	padding: 0;

	cursor: default;
	pointer-events: none;

	border: none;
	border-radius: 12px 0 0 12px;
	background-color: transparent;
}

.searchIcon {
	flex: 0 0 auto;

	width: 36px;
	height: 24px;

	opacity: 0.5;
}

.clear {
	position: absolute;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 30px;
	height: 40px;
	margin-right: 5px;
	padding: 0;

	cursor: pointer;

	border: none;
	border-radius: 0 12px 12px 0;
	background-color: transparent;
}

.clearIcon {
	width: 18px;
	height: 18px;
	padding: 2px;

	border: 1px solid var(--color-gray-dark);
	border-radius: 50%;
}

.pseoudoInput {
	position: relative;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	color: #757575;
	border: 1px rgb(0 0 0 / 0%) solid;
	background-color: var(--color-fill);
}

.hiddenPseoudoInput {
	z-index: 0;

	display: none;

	width: 0;
	height: 0;

	pointer-events: none;

	font-size: 0;
	line-height: 0;
}
