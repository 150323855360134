.breadcrumbs {
	display: flex;
	flex-wrap: nowrap;

	color: var(--color-gray);
}

.breadcrumbsItem {
	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.breadcrumbsItem:hover {
	color: var(--color-gray-dark);
}

.currentItem {
	cursor: default;

	color: var(--color-black);
}

.currentItem:hover {
	color: var(--color-black);
}

.breadcrumbsItem .delimeter {
	padding: 0 16px;
}
