/* stylelint-disable indentation */
.product {
  padding: 16px 0;
}

.row {
	display: flex;
	flex-direction: column;
}

.counter {
    margin-top: 12px;
}

.description {
	display: inline;

	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
}

.price {
	display: flex;
	align-items: flex-end;
	flex-direction: column;

	margin-top: 10px;

	white-space: nowrap;

	color: var(--color-black);

	/* margin-left: 10px; */
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

.hiddenPrice {
	display: none;
}

.oldCost {
	text-decoration: line-through;

	font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
}

.cost {
	white-space: nowrap;

	font-size: 16px;
    font-weight: 500;
}

.redPrice {
	color: #F00;
}

.img {
	position: relative;

	overflow: hidden;
	align-self: flex-start;

	width: 85px;
	height: 85px;
	margin-left: 5px;

	border-radius: 16px;
	box-shadow: 1px 1px 6px rgb(33 32 31 / 10%);
}

.image {
  overflow: hidden;
}

.count {
  margin-left: 18px;
}

.counterDish {
	margin: 0 0 0 20px;

	color: var(--color-gray);

	font-size: 14px;
}

.btn {
	display: flex;
	column-gap: 15px;
}

.wrapper {
    display: flex;
    align-items: flex-start;
    align-self: center;
    justify-content: center;
}

.additivesWrapper {
	margin-top: 8px;
	padding-left: 112px;
}

.additiveItem {
    margin: 0;
    padding: 0;

    vertical-align: baseline;

    color: #B0B0B0;
    border: 0;

    font: inherit;
    font-size: 14px;
    line-height: 1em;
}

.svg {
    width: 48px;
    height: 48px;
}

.itemOptions {
    display: flex;
    flex: 1 0 calc(100% - 90px);
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding-left: 22px;
}

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portion {
	margin: 8px 0 2px;

	color: #999;

	font-size: 16px;
	font-weight: 400;
}

@media screen and (min-width: 1280px) {
	.row {
        display: flex;
        align-items: center;
        flex-direction: unset;

        width: 100%;
    }

    .info {
        flex: 3;

        width: 100%;
    }

    .wrapper {
        width: 100%;
    }
}
