.buttonAddPromocode {
	width: 100%;
	padding: 0 12px;
}

.buttonText {
	text-align: center;
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: var(--color-black);

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.buttonAddPromocode.success {
	text-align: left;

	background-color: #E5F7E6;
}
