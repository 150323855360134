.plate {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;

	width: 100%;
	height: 54px;
	margin-bottom: 8px;
	padding: 0 20px;

	border-radius: 12px;
	background: #E7F2DE;
}

.textCenter {
	justify-content: center;
}

.icon {
	flex-shrink: 0;

	width: 32px;
	height: 32px;
	margin-right: 8px;
}

.text {
	letter-spacing: -0.01em;

	color: #32760A;

	font-size: 17px;
	font-weight: 400;
	line-height: 20px;
}

.plateSizeSmall {
	height: 44px;
}

.plateSizeSmall .text {
	font-size: 14px;
}

.plateSizeSmall .icon {
	width: 28px;
	height: 28px;
}

.plateMobile {
	height: 46px;
	padding: 0 12px;
}

.plateMobile .icon {
	width: 26px;
	height: 26px;
}

.plateMobile .text {
	letter-spacing: -0.01em;

	color: #469F12;

	font-size: 14px;
	line-height: 18px;
}
