.buttonAddPromocode {
	width: 100%;
}

.buttonText {
	text-transform: uppercase;
}

.buttonAddPromocode.success {
	text-align: left;

	background-color: #E5F7E6;
}
