.wrapper {
	position: fixed;

	/* z-index: 100; */
	z-index: 10000;
	bottom: 0;
	left: 0;

	width: 100%;
}

.window {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
}

.visible {
	pointer-events: all;
}

.hidden {
	pointer-events: none;
}

.cartBarLayout {
	overflow: hidden;

	width: 100%;
	height: 70px;
	padding: 8px 12px;

	transition: all 0.3s ease-in;

	opacity: 0;
	background-color: #FFF;

	box-shadow: 0 -4px 20px 2px rgb(0 0 0 / 10%);
}

.partnerBar {
	height: 114px;
}

.visible .cartBarLayout {
	animation: fade 0.2s ease-in-out forwards;
}

.hidden .cartBarLayout {
	animation: fade-out 0.2s ease-in-out forwards;
}

@keyframes fade {
	from {
		transform: translateY(100%);

		opacity: 0;
	}

	to {
		transform: translateY(0);

		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		transform: translateY(0);

		opacity: 1;
	}

	to {
		transform: translateY(100%);

		opacity: 0;
	}
}

.deliveryMetaInfo {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;

	height: 36px;
	margin-bottom: 8px;
}

.deliveryIcon {
	width: 20px;
	height: 20px;
}

.info {
	flex-grow: 1;

	text-align: center;

	font-size: 14px;
	line-height: 18px;
}

.text {
	font-size: 16px;
	font-weight: 400;
}

.textSuccess {
	color: #19A93F;
}

.textSmall {
	font-size: 14px;
	font-weight: 400;
}

.supText {
	color: #F00;

	font-size: 14px;
}

.subText {
	color: #19A93F;

	font-size: 14px;
}

.arrowIcon {
	transform: rotate(-90deg);
}

.orderPlateButton {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	justify-content: space-between;

	width: 100%;

	text-transform: capitalize;

	font-size: 18px;
	font-weight: 400;
	line-height: 38px;
}

.checkoutText {
	flex: 1 1 auto;

	text-align: center;
	letter-spacing: -0.01em;
	text-transform: uppercase;

	font-size: 14px;
	font-weight: 500;
}

.leftBlock {
	position: relative;

	flex-grow: 1;
	flex-shrink: 1;

	padding-right: 12px;

	text-align: right;
}

.leftBlock::after {
	position: absolute;
	top: 0;
	right: 0;

	height: 38px;

	content: "";

	border-left: 1px solid #FFF;
}

.rightBlock {
	flex-grow: 1;
	flex-shrink: 0;

	min-width: 50%;
	max-width: 70%;
	padding-left: 12px;

	text-align: left;
}

.cartIcon {
	width: 22px;
	height: 22px;
	margin-right: 12px;

	vertical-align: text-top;
}

.cartIcon path {
	stroke: #FFF;
}

.amount {
	margin-left: auto;

	letter-spacing: 0.1em;
}

.cartName {
	line-height: 22px;
}

/* DELIVERY INFO MODAL */
.aboutDelivery {
	display: flex;
	flex-direction: column;

	color: #212529;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	gap: 16px;
}

.modalTitle {
	margin: 0;

	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

.modalItemTitle {
	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.prices {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.pricesItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
