.promotions {
	overflow-x: auto;
	overflow-y: hidden;

	margin: 0 -16px;
	margin-bottom: 16px;

	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none;
}

.marginBottom {
	margin-bottom: 24px;
}

.promotions::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.gridPromotions {
	display: flex;

	width: max-content;

	gap: 0  12px;

	padding: 0 16px;
}

.slide {
	overflow: hidden;
}
