.button {
	display: flex;

	padding: 0 14px;

	transition: all 0.2s linear;
	transition-property: color, background;
	letter-spacing: -0.01em;

	border: none;
	background: none;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.wrapper {
	position: relative;
	z-index: 1;

	display: inline-flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

.content {
	display: inline-flex;
	align-items: center;
	flex: 1 1 auto;
	justify-content: center;

	width: 100%;
}

.isLoading.content {
	position: relative;
}

.contentHide {
	position: relative;

	animation: hideContent 0.4s ease-in-out forwards;
}

@keyframes hideContent {
	0% {
		top: 0;

		opacity: 0;
	}

	100% {
		top: 100%;
	}
}

.contentVisible {
	position: relative;

	top: 100%;

	animation: loadingContent 0.4s ease-in-out forwards;
}

@keyframes loadingContent {
	0% {
		top: 100%;

		opacity: 0;
	}

	100% {
		top: 0;

		opacity: 1;
	}
}

.loading {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.loadingVisible {
	animation: loadingVisible 0.4s ease-in-out forwards;
}

@keyframes loadingVisible {
	from {
		top: -100%;
	}

	to {
		top: 0;
	}
}

.loadingHide {
	animation: loadingHide 0.4s ease-in-out forwards;
}

@keyframes loadingHide {
	from {
		top: 0;
	}

	to {
		top: -100%;
	}
}

/* ================= BUTTON SIZES ================= */
.small {
	height: 36px;

	border-radius: 8px;
}

.medium {
	height: 40px;

	border-radius: 10px;
}

.large {
	height: 44px;

	border-radius: 12px;
}

.huge {
	height: 54px;
	padding: 0 18px;

	border-radius: 12px;
}

/* ============================ */

/* ================= BUTTON TYPES  ================= */

/* PRIMARY */
.primary {
	color: var(--color-white);
	background-color: var(--color-primary);
}

.primary:disabled:not(.isLoading) {
	color: var(--color-white);
	background-color: var(--color-gray);
}

.primary:not(:disabled):active {
	background-color: var(--color-primary-active);
}

/* GRAY */
.gray {
	color: var(--color-black);
	background-color: var(--color-silver);
}

.gray:disabled:not(.isLoading) {
	background-color: var(--color-silver);
}

.gray:not(:disabled):active {
	background-color: var(--color-silver-active);
}

/* GOST */
.gost {
	color: var(--color-black);
	background-color: transparent;
}

.gost:disabled:not(.isLoading) {
	color: var(--color-dull-black);
	background-color: transparent;
}

.gost:not(:disabled):active {
	background-color: var(--color-silver);
}

/* TEXT */
.text {
	padding: 0;

	color: var(--color-dull-black);
	background-color: transparent;
}

.text:disabled:not(.isLoading) {
	color: var(--color-dull-black);
	background-color: transparent;
}

.text:not(:disabled):active {
	color: var(--color-black);
}

.buttonPressed:not(:disabled) {
	transform: perspective(400px) scaleZ(1.3) translateZ(-12px);
}
