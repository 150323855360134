.wrapper {
	position: fixed;
	z-index: 10002;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay {
	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;

	will-change: opacity;

	background: var(--color-overlay);
}

.wrapper.open .overlay {
	animation: fade 0.4s ease-in-out forwards;
}

.wrapper.close .overlay {
	animation: fade-out 0.4s ease-out forwards;
}

.window {
	position: absolute;

	bottom: 0;

	overflow: hidden;

	width: 100%;

	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	background: var(--color-white);
	will-change: transform, opacity;
}

.wrapper.open .window {
	animation: slide-up 0.4s ease-in-out forwards;
}

.wrapper.close .window {
	animation: slide-out 0.4s ease-in-out forwards;
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes slide-up {
	from {
		transform: translateY(100%);
	}

	to {
		transform: translateY(0);
	}
}

@keyframes slide-out {
	0% {
		transform: translateY(0);
	}

	50% {
		opacity: 1;
	}

	100% {
		transform: translateY(100%);

		opacity: 0;
	}
}

.content {
	width: 100%;
	height: 100%;
	padding: 16px;
	padding-bottom: 24px;
}
