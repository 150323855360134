.wrapper {
	position: fixed;
	z-index: 10002;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay {
	position: fixed;

	top: 0;
	left: 0;

	overflow: hidden;

	width: var(--overlay-width);
	height: 100vh;

	will-change: transform, opacity;
}

.wrapper.open .overlay {
	animation: fade 0.3s ease-in forwards;
	animation-duration: 0.2s;
}

.wrapper.close .overlay {
	animation: fade-out 0.3s ease-out forwards;
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.backgroundOverlay {
	background: var(--color-overlay);
}

.window {
	position: absolute;
	bottom: unset;

	overflow: hidden;

	width: auto;

	border-radius: 24px;
	background: var(--color-white);
	box-shadow: 0 5px 13px 3px rgb(0 0 0 / 12%);

	/* opacity: 0; */
}

.content {
	width: 100%;
	height: 100%;
	padding: 20px;
}
