.buttonSelectPayment {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;
}

.paymentIcon {
	flex: 0 0 auto;

	width: 45px;
	height: 30px;
	margin-right: 8px;
}

.paymentText {
	margin-right: auto;

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.arrowIcon {
	flex: 0 0 auto;

	width: 24px;
	height: 24px;
}
