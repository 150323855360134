.tag {
	position: relative;

	display: inline-flex;
	align-items: center;
	flex-direction: column;

	width: 78px;
	height: 94px;
	padding: 4px 8px;

	transition: all 0.25s ease;
	text-align: center;

	border-radius: 16px;
	background-color: #FFF;
	box-shadow: 1px 3px 8px rgb(0 0 0 / 4%);
}

.imageWrapper {
	position: relative;
	z-index: 101;

	overflow: hidden;

	width: 54px;
	height: 54px;

	border-radius: 50%;
}

.title {
	position: relative;
	z-index: 101;

	margin: auto 0;

	text-align: center;
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
}
