.button {
	display: inline-flex;
	align-items: center;

	padding: 0;

	cursor: pointer;

	color: black;

	border: none;
	background: transparent;

	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

.arrowIcon {
	width: 32px;
	height: 32px;
	margin-right: 10px;
}

.button span {
	margin-left: 8px;

	color: #4D4D4D;

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.lite {
	padding: 8px;

	transition: background-color 0.3s ease;

	border-radius: 12px;
	background-color: var(--color-silver);
}

.lite:hover {
	background-color: var(--color-silver-hover);
}

.lite:active {
	background-color: var(--color-silver-active);
}
