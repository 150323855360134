.buttonDelivery {
	position: relative;

	display: flex;
	align-items: center;

	padding: 6px 10px;
}

.icon {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.buttonText {
	width: 92px;

	text-align: left;
	white-space: normal;
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: var(--color-gray-dark);

	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	text-decoration-line: underline;
}

.deliveryInfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;
}
