.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0;

	color: var(--color-black);

	font-weight: 600;
	font-style: normal;
}

.h1Title {
	margin-bottom: 24px;

	letter-spacing: -0.02em;

	font-size: 32px;
	font-weight: 700;
	line-height: 38px;
}

.h2Title {
	margin-bottom: 16px;

	letter-spacing: -0.02em;

	font-size: 28px;
	font-weight: 700;
	line-height: 34px;
}

.h3Title {
	margin-bottom: 16px;

	letter-spacing: -0.015em;

	font-size: 22px;
	font-weight: 700;
	line-height: 26px;
}

.h4Title {
	margin-bottom: 12px;

	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}

.h5Title {
	margin-bottom: 12px;

	font-size: 18px;
	font-weight: 500;
	line-height: 20px;
}

.h6Title {
	margin-bottom: 12px;

	font-size: 16px;
	font-weight: 500;
	line-height: 18px;
}

.noneMargin {
	margin: 0;
}

.noneWrap {
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}
