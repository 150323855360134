.loader {
	position: fixed;
	z-index: 1001;
	top: 0;

	width: 0;
	height: 2px;

	animation: load 2s linear forwards;

	background: var(--color-primary);
}

@keyframes load {
	from {
		width: 0;
	}

	to {
		width: 100vw;
	}
}
