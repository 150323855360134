.wrapper {
	display: flex;
	overflow: hidden;
	flex-direction: column;

	width: 376px;
	max-height: 80vh;
	margin-right: -20px;
	padding-right: 20px;
}

.cartPopup {
	flex: 1 1 auto;

	height: 100%;
	margin-right: -20px;
	padding-right: 20px;
}
