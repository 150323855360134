.tags {
	overflow-x: auto;
	overflow-y: hidden;

	margin: 0 -16px;
	padding: 16px 0;

	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none;
}

.tags::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.gridTag {
	display: flex;

	width: max-content;
	padding: 0 16px;
}

.tagItem {
	padding-right: 8px;
}

.tagItem:last-child {
	padding-right: 0;
}

.beforeElement::before {
	position: absolute;
	z-index: 100;
	top: 4px;
	left: calc(50% - 27px);

	width: 54px;
	height: 54px;

	content: "";
	transition: all 0.25s ease;

	border-radius: 50%;
}

.pressedTag {
	transform: perspective(400px) scaleZ(1.5) translateZ(-15px);
}

.pressedTag::before {
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	border-radius: 16px;
}

.pressedLink1::before {
	background-color: #FDE9D5;
}

.pressedLink2::before {
	background-color: #FAF2EA;
}

.pressedLink3::before {
	background-color: #F4F6E0;
}

.pressedLink4::before {
	background-color: #FBEAF0;
}

.pressedLink5::before {
	background-color: #E8F9E2;
}

.pressedLink6::before {
	background-color: #F5EDF7;
}

.pressedLink7::before {
	background-color: #EEEFFB;
}

.pressedLink8::before {
	background-color: #E7F3FD;
}

.pressedLink9::before {
	background-color: #FFF4F1;
}

.pressedLink10::before {
	background-color: #D4E5F4;
}

.pressedLink11::before {
	background-color: #E7EBFD;
}

.activeLink1 {
	background-color: #FDE9D5;
}

.activeLink2 {
	background-color: #FAF2EA;
}

.activeLink3 {
	background-color: #F4F6E0;
}

.activeLink4 {
	background-color: #FBEAF0;
}

.activeLink5 {
	background-color: #E8F9E2;
}

.activeLink6 {
	background-color: #F5EDF7;
}

.activeLink7 {
	background-color: #EEEFFB;
}

.activeLink8 {
	background-color: #E7F3FD;
}

.activeLink9 {
	background-color: #FFF4F1;
}

.activeLink10 {
	background-color: #D4E5F4;
}

.activeLink11 {
	background-color: #E7EBFD;
}
