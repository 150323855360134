.header {
	width: 100%;

	transition: all 0.3s ease;

	border-bottom: 1px solid rgb(0 0 0 / 10%);

	background-color: var(--color-white);
}

.isScrollHeader {
	transition: all 0.3s ease;

	border-color: transparent;
	box-shadow: 0 7px 25px rgb(117 116 112 / 20%);
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;

	max-width: var(--desktop-page-width);
	height: 100%;
	margin: 0 auto;

	padding: 0 24px;
}

.logoContainer {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	justify-content: center;
}

.logo {
	width: 160px;
	min-width: 160px;
}
