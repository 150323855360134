.button {
	position: relative;
	z-index: 10;

	display: flex;
	align-items: center;

	width: 100%;
	height: 44px;

	cursor: pointer;

	border: none;
	border-radius: 12px;

	font-size: 16px;
}

.empty {
	justify-content: center;

	padding: 8px 12px;

	user-select: none;
	user-select: none;
	user-select: none;
	user-select: none;
	transition: 0.3s ease-in-out;
	transition: 0.2s;

	color: var(--color-black);
	background-color: var(--color-gray-light);

	line-height: 28px;
}

.empty:hover {
	transition: 0.2s;

	background-color: var(--color-gray-light-hover);
}

.empty:active {
	background: #DFDFDF;
}

.empty:focus-visible {
	outline: none;
}

.counter {
	position: relative;
	z-index: 10;

	display: flex;
	overflow: hidden;
	align-items: stretch;
	justify-content: space-between;

	height: 44px;
	padding: 0;

	color: var(--color-white);
	border-radius: 12px;
}

.count {
	align-self: center;

	min-width: 32px;

	text-align: center;

	color: var(--color-black);

	font-weight: 500;
}

.counter .counterButton {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;
	margin: 0;
	padding: 0;

	cursor: pointer;
	transition: 0.2s;

	border: none;
	border-radius: 12px;
	outline-offset: -2px;
	background-color: var(--color-gray-light);
}

.counter .counterButton:hover {
	transition: 0.2s;

	background-color: var(--color-gray-light-hover);
}

.counterIcon {
	width: 20px;
	height: 20px;
	fill: var(--color-black);
}
