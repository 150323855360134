.header {
	width: 100%;

	transition: all 0.3s ease;

	border-bottom: 1px solid var(--color-border-light);
}

.isScrollHeader {
	transition: all 0.3s ease;

	border-color: transparent;
	box-shadow: 3px 7px 20px rgb(122 122 122 / 9%);
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;

	max-width: 1400px;
	height: 100%;
	margin: 0 auto;
}

.logo {
	display: none;
}

.logoTitle {
	height: 100%;
}

@media screen and (min-width: 750px) {
	.logo {
		display: unset;

		width: 160px;
		min-width: 160px;
	}

	.logoTitle {
		display: none;
	}
}
