.wrapper {
	position: relative;

	height: 100%;

	transition: all 0.25s ease;

	border-radius: 16px;

	/* background: var(--color-fill); */
	background-color: #FFF;
	box-shadow: 1px 3px 14px rgb(0 0 0 / 8%);
}

.pressed {
	transform: perspective(400px) scaleZ(1.5) translateZ(-5px);
}

.closingPlaceNone {
	position: absolute;
	top: 0;
	left: 0;

	display: none;

	height: 28px;
	padding: 0 12px;

	letter-spacing: 0.03rem;

	color: #FFF;
	border-radius: 0;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 30px;
	background-color: #3F4246C2;

	font-size: 13px;
	font-weight: 500;
	line-height: 28px;
}

.closingPlace {
	display: unset;
}

.avgDeliveryTimePlace {
	position: absolute;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;

	height: 32px;

	padding: 6px 9px 6px 8px;

	letter-spacing: -0.01em;

	color: #FFF;

	border-radius: 16px 0 0 16px;
	background: rgb(69 68 68 / 85%);

	font-size: 14px;

	font-weight: 400;
	line-height: 18px;
}

.avgTimeIsNone {
	display: none;
}

.manSvg {
	margin-right: 4px;
}

.imgWrapper {
	position: relative;

	overflow: hidden;

	max-width: 100%;
	height: 160px;

	border-radius: 16px;
}

.imgPartnerClose {
	/* opacity: 0.5; */
	filter: opacity(0.5);

	transition: opacity 0.2s ease;
}

.info {
	padding: 8px 16px;
}

.name {
	overflow: hidden;

	white-space: nowrap;

	text-overflow: ellipsis;
}

.subTitle {
	overflow: hidden;

	margin-top: 2px;

	white-space: nowrap;

	text-overflow: ellipsis;

	color: var(--color-gray-dark);

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
}

.meta {
	display: flex;

	height: 27px;
	margin-top: 16px;
}

.starSvg,
.clockSvg,
.bagSvg {
	fill: var(--color-primary);

	width: 18px;
	height: 18px;
}

.starSvg {
	width: 15px;
	height: 15px;
}

.raiting {
	display: flex;
	align-items: center;
}

.cost {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.deliveryTime {
	display: flex;
	align-items: center;

	margin-left: 15px;
}

.metaSubTextSpan {
	margin: 2px 0 0 4px;

	color: var(--color-gray-dark);

	font-size: 13px;
	font-weight: 600;
	line-height: 15px;
}
