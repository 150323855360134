.wrapper {
	width: 100%;
	height: 100%;

	background: var(--color-white);
}

.iconContainer {
	margin-bottom: 24px;

	text-align: center;
}

.message {
	text-align: center;
	letter-spacing: -0.01em;

	color: #4D4D4D;

	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
}

.btnWrapper {
	display: flex;
	justify-content: center;

	width: 100%;
	padding-top: 16px;
	gap: 0 12px;
}

.button {
	width: 50%;

	font-size: 16px;
	line-height: 20px;
}
