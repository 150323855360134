/* stylelint-disable indentation */
.minusBtn,
.PlusBtn {
    display: flex;

    width: 38px;

    padding: 4px;

	cursor: pointer;

	transition: all 0.2s ease-in-out;

	transition-property: color, background;

	border: none;

	background: none;

	font-weight: 500;
}

.PlusBtn {
    border-radius: 0 12px 12px 0;
}

.counterBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 44px;
    height: 44px;

    transition: 0.2s ease-in-out;

    border-radius: 12px;
    background-color: #EBEBEB;
}

.counterBtn:hover {
    transition: 0.2s ease-in-out;

	box-shadow: 0 2px 6px rgb(0 0 0 / 8%);
}

.counterBtn:active {
    background: #DFDFDF;
	box-shadow: 0 2px 4px rgb(0 0 0 / 6%);
}

.counterBtn svg {
    width: 22px;
    height: 22px;
}

.CounterBody {
    display: flex;
    align-items: center;

    border-radius: 12px;
}

.count {
    position: relative;

    height: 38px;
    padding: 7px 18px;

    font-size: 16px;
    font-weight: 500;
}

.sht {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.counter {
    display: flex;
    justify-content: flex-start;

    margin-top: 8px;
}
