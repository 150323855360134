.textarea {
	display: inline-block;

	padding: 10px 14px;

	transition: all 0.2s ease-in-out;

	transition-property: color, background;

	border: none;

	border: 1px solid #D9D9D9;
	border-radius: 12px;
	outline: none;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.textarea:focus,
.textarea:active {
	border: 1px #B3B3B3 solid;
}

.small {
	padding: 10px 48px;

	font-size: 16px;
	line-height: 10px;
}

.large {
	padding: 14px 42px;

	font-size: 18px;
	line-height: 25px;
}

.fill {
	color: var(--color-black);
	background-color: var(--color-fill);
}

.outline {
	border: 1px solid #D9D9D9;
	outline: none;
}

.outline:hover,
.outline:active {
	outline: none;
}

.noResize {
	resize: none;
}
