.wrapper {
	display: flex;
	align-items: center;

	height: 22px;
}
/* stylelint-disable */

.input {
	position: absolute;
	z-index: -1;

 
	display: none;

	opacity: 0;
}

.input   {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.checkbox {
	display: inline-block;
	flex-grow: 0;
	flex-shrink: 0; 

	width: 20px;
	height: 20px;
	margin-right: 0.5em;

	content: "";

	border: 1px solid var(--color-overlay);
	border-radius: 0.25em;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.checkbox__checked {
	border-color: transparent;

	background: url(~/src/assets/icons/check-white.svg) no-repeat center center;
	background-color: var(--color-primary);
	background-size: 12px;
}
