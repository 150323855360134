.userWrapper {
	position: relative;

	display: flex;
	align-items: center;
	flex: 0 0 auto;

	width: 68px;
	height: 100%;

	padding: 0;
	padding-right: 16px;
	padding-left: 12px;
}

.loginButton {
	display: flex;
	align-items: center;
	flex: 0 0 auto;
	justify-content: center;

	width: 40px;
	height: 40px;
	padding: 6px 0;
	padding-left: 10px;

	border: none;
	border-radius: 10px;
}

.loginButton svg {
	width: 30px;
	height: 30px;

	opacity: 0.7;
}

.mobileMenu {
	display: flex;
	overflow-y: auto;
	flex: 1 1 auto;
	flex-direction: column;

	width: 100%;
	max-width: 325px;

	background: #FFF;
}

@media (min-width: 540px) {
	.mobileMenu {
		max-width: 345px;
	}
}

.headerMenu {
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: var(--mobile-header-height);
	padding: 8px 16px;

	box-shadow: 0 4px 8px rgb(0 0 0 / 4%);
	gap: 8px;
}

.mobileTitle {
	display: block;

	width: 150px;
	height: 36px;

	background-image: url("~src/assets/svg/logo-black-outlined.svg");
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: contain;
}

.mobileClose {
	display: flex;

	padding: 5px;
}

.bodyMenu {
	display: flex;
	overflow: hidden;
	overflow: auto;
	flex-direction: column;
	flex-grow: 1;

	padding: 16px 24px 20px;

	background-color: #FFF;
	gap: 12px;
}

.bodyMenu::-webkit-scrollbar { width: 0; }

.mobileAuth {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	justify-content: center;
}

.mobileName {
	margin: 0;

	color: var(--color-black);

	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

.mobileList {
	display: flex;
	flex-direction: column;

	margin: 0;
	padding: 0;

	list-style: none;
	gap: 12px;
}

.mobileItem > * {
	display: flex;
	align-items: center;

	padding: 8px 0;
}

.itemName {
	width: calc(100% - 76px);
	margin: 0 16px;
}

.mobileLogout {
	width: 100%;
	height: 46px;

	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: #4D4D4D;
	border: 0;
	border-radius: 10px;
	background: #F5F5F5;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.socials {
	display: flex;
	flex-direction: column;

	margin: 0 -24px;
	padding: 10px 24px;

	border-top: 1px solid #D9D9D9;
	border-bottom: 1px solid #D9D9D9;
	gap: 18px 34px;
}

.social {
	display: flex;
	align-items: center;

	letter-spacing: -0.01em;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	gap: 8px;
}

.mobileFooter {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	padding: 12px 24px;
	gap: 12px;
}

.footerText {
	margin: 0;
}

.buttPhoneSupport {
	position: relative;

	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

.phoneSupport {
	margin-left: 8px;

	color: var(--color-black);

	font-size: 14px;
}

.name {
	display: box;
	overflow: hidden;

	margin-left: 10px;
	padding-right: 9px;

	text-align: left;

	color: #646464;

	font-size: 14px;
	font-weight: 500;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
