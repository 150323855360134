.wrapper {
	position: fixed;
	z-index: 10001;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	/* padding-bottom: calc(0px + var(--safe-area-inset-bottom, 0px)); */

	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	margin-bottom: 12px;

	text-align: center;
}

.overlay {
	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;

	will-change: opacity;

	background: var(--color-overlay);
}

.wrapper.open .overlay {
	animation: fade 0.3s ease-in forwards;
}

.wrapper.close .overlay {
	animation: fade-out 0.3s ease-out forwards;
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.window {
	position: fixed;
	z-index: 10002;
	top: 0;
	right: 0;
	bottom: 0;

	display: flex;
	overflow: hidden;
	flex-direction: column;

	width: auto;
	height: 100vh;
	padding: 20px;

	transition-property: transform, opacity;

	background: var(--color-white);
}

.wrapper.open .window {
	animation: slide-up 0.3s ease-in-out forwards;
}

.wrapper.close .window {
	animation: slide-out 0.3s ease-in-out forwards;
}

@keyframes slide-up {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}

@keyframes slide-out {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(100%);
	}
}

.content {
	width: 100%;
	height: 100%;
}

.closeButton {
	position: absolute;
	z-index: 10005;
	top: 16px;
	right: 16px;

	width: 40px;
	height: 40px;
	margin-top: 0;
	margin-left: auto;
	padding: 0;

	cursor: pointer;
	transform: unset;

	border: none;
	border-radius: 50%;
	background-color: var(--color-primary);
}

.closeIcon {
	display: unset;

	width: 20px;
	height: 20px;

	vertical-align: middle;
}

.closeIcon path {
	stroke: #FFF;
	fill: #FFF;
}
