.wrapper {
	display: flex;

	overflow: hidden;
	flex-direction: column;

	width: 100%;

	background: var(--color-white);
}

.mobileModal.open {
	bottom: 0;
}

.mobileBackTop {
	display: flex;
	justify-content: space-between;

	margin-bottom: 24px;
}

.mobileBackTitle {
	color: var(--color-black);

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.mobileClose {
	display: flex;
}

.mobileBackText {
	letter-spacing: -0.01em;

	color: #4D4D4D;

	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.mobileBackText.textMargin {
	margin-bottom: 24px;
}

.mobileCopied {
	max-height: 0;
	margin-bottom: 0;

	transition: all 0.2s linear;
	text-align: center;
	letter-spacing: -0.01em;

	color: #999;

	font-size: 0;
	font-weight: 400;
	line-height: 18px;
}

.mobileCopied.isCopied {
	max-height: 18px;
	margin-bottom: 24px;

	font-size: 14px;
}

.mobileBottom {
	display: flex;
	align-items: center;
	gap: 12px;
}

.mobilePromoCode,
.mobileCopyBtn {
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobilePromoCode {
	width: calc(100% - 58px);
	height: 48px;

	letter-spacing: -0.01em;
	text-transform: uppercase;

	border-radius: 10px;
	background: #F5F5F5;

	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
}

.mobileCopyBtn {
	width: 46px;
	height: 46px;

	border-radius: 8px;
	background: #FEEBEA;
}

@media screen and (min-width: 750px) and (max-width: 1279px) {
	.wrapper {
		align-items: center;
	}

	.mobileModal {
		width: 500px;

		border-radius: 12px;
	}
}

@media screen and (min-width: 1280px) {
	.wrapper {
		align-items: center;

		padding: 40px;
	}

	.window {
		width: 546px;

		margin: auto 0;

		border-radius: 16px;
		box-shadow: 0 11px 24px rgb(0 0 0 / 12%);
	}

	.overlay {
		background-color: transparent;
	}

	.wrapper.open .window {
		animation: slide-up 0.2s ease-in-out forwards;
	}

	.wrapper.close .window {
		animation: slide-out 0.2s ease-in-out forwards;
	}

	@keyframes slide-up {
		from {
			transform: translateY(15%);
		}

		to {
			transform: translateY(0);
		}
	}

	@keyframes slide-out {
		from {
			transform: translateY(0);

			opacity: 1;
		}

		to {
			transform: translateY(15%);

			opacity: 0;
		}
	}
}
