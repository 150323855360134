.button {
	display: flex;
	align-items: center;
	flex: 0 0 auto;
	justify-content: flex-end;

	width: 68px;
	height: 100%;

	padding: 0;
	padding-right: 12px;
	padding-left: 16px;

	border: none;
	background-color: transparent;
}

.arrow {
	width: 40px;
	height: 40px;
	padding: 6px;

	border-radius: 10px;
}

.light .arrow {
	background-color: var(--color-fill);
}

.arrowIcon {
	width: 100%;
	height: 100%;
}
