.cartIcon {
	width: 20px;
	height: 20px;
}

.cartIcon path {
	stroke: #FFF;
}

.amount {
	display: block;

	margin-top: 2px;
	margin-left: 12px;

	white-space: nowrap;

	line-height: 20px;
}

.basket {
	width: 400px;
	height: 100%;
	margin: 0;

	background-color: var(--color-white);
}
