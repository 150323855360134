.metaPlate {
	display: flex;
	align-items: center;

	padding-right: 6px;

	cursor: pointer;
	transition: 0.3s ease;

	border-radius: 12px;
}

.metaPlate:hover {
	transition: 0.3s ease;

	background: #F0EEEE;
}

.metaPlateInfo {
	margin: auto 2px;

	text-align: left;
}

.metaPlateIcon {
	display: flex;
	align-items: center;

	height: 40px;
	margin-right: 8px;
	padding: 0 10px;

	letter-spacing: 0.05em;

	color: var(--color-black);
	border-radius: 12px 12px 0;
	background: #F0EEEE;

	font-size: 18px;

	font-weight: 700;
	line-height: 20px;
}

.metaSubTitle {
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: var(--color-black);

	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
}

.metaSubText {
	letter-spacing: -0.01em;
	text-transform: uppercase;

	color: #757575;

	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	text-decoration-line: underline;
}

.starSvg {
	width: 24px;
	height: 24px;
	padding: 1px;
	fill: var(--color-primary);
}

.starSvg {
	width: 20px;
	height: 20px;
	margin-right: 4px;
}

.ratingHeader {
	display: flex;
	flex-direction: column;
	gap: 16px;

	padding-bottom: 16px;

	border-bottom: 1px solid #D9D9D9;
}

.ratingFeedbackTop {
	display: flex;
	justify-content: space-between;
}

.ratingTitle {
	color: #212529;

	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

.ratingClose {
	display: flex;

	padding: 0;

	cursor: pointer;

	border: 0;
	background: transparent;
}

.ratingHeaderItems {
	display: flex;
	align-items: center;
	gap: 16px;
}

.ratingHeaderItem {
	display: flex;
	flex: 0 0 calc(50% - 8px);
	flex-direction: column;
	gap: 8px;
}

.ratingHeaderItemTitle {
	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.ratingHeaderSubItem {
	display: flex;
	align-items: center;

	width: min-content;
	padding: 10px;

	border-radius: 12px 12px 0;
	background: #F5F5F5;
	gap: 4px;
}

.ratingHeaderValue {
	color: var(--color-black);

	font-size: 28px;
	font-weight: 600;
	line-height: 36px;
}

.ratingScroll {
	position: relative;

	overflow: hidden;

	min-height: 200px;
	padding: 0;
}

.ratingBody {
	overflow-y: auto;

	max-height: 570px;
	margin: 0;
	padding: 0 6px 0 0;

	list-style: none;
}

.ratingBody::-webkit-scrollbar {
	width: 5px;
}

.ratingFeedback {
	padding: 16px 0;

	border-bottom: 1px solid #D9D9D9;
}

.ratingFeedbackDate {
	letter-spacing: -0.01em;

	color: #212529;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.ratingFeedbackDate span {
	margin-right: 8px;
}

.ratingStars {
	display: flex;
	align-items: center;
	gap: 2px;
}

.ratingFeedbackFilled {
	fill: #FF4C30;
}

.ratingFeedbackItems,
.ratingFeedback {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.ratingFeedbackItem {
	display: flex;
	align-items: center;
	gap: 8px;
}

.ratingIcon {
	flex: 0 0 auto;
}

.ratingFeedbackText {
	letter-spacing: -0.01em;

	color: #212529;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}
