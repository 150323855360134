/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-id-pattern */

/* @import 'assets/fonts/TTNorms/style.css'; */

:root {
	--color-white: #FFF;
	--color-black: #212529;
	--color-dull-black: #4D4D4D;
	--color-light: #BEBEBE;
	--color-gray-dark: #757575;
	--color-gray: #B3B3B3;
	--color-gray-hover: #A7A7A7;
	--color-gray-light: #F2F2F2;
	--color-gray-light-hover: #E3E3E3;
	--color-gray-light-active: #DDD;
	--color-finish: #1EB62D;
	--color-finish-light: #21D248;
	--color-primary: #FF4C30; 	/* #FF4C30  */
	--color-primary-hover: #FA4225;/* e64a3b */
	--color-primary-active: #E72C1B;
	--color-primary-light: #FFE4E0;
	--color-primary-light-hover: #FFDAD4;
	--color-secondary: #F09380;
	--color-fill: #F5F5F5;
	--color-fill-hover: #EBEBEB;
	--color-fill-active: #DDD;
	--color-silver: #EBEBEB;
	--color-silver-hover: #DDD;
	--color-silver-active: #CCC;
	--color-tertiary: rgb(255 76 48 / 32%);
	--color-background-accent: rgb(255 76 48 / 16%);
	--color-yellow: #F2D84D;
	--color-overlay: rgb(0 0 0 / 25%); /* 40% */
	--color-border: #B3B3B3;
	--color-border-focus: var(--color-dull-black);
	--color-border-light: #E6E6E6;
	--desktop-header-height: 82px;
	--mobile-header-height: 56px;
	--footer-height: 176px;
	--scrollbar-width: 8px;
	--scrollbar-height: 8px;
	--body-scrollbar-width: 10px;
	--body-scrollbar-height: 12px;
	--desktop-content-min-width: 1080px;
	--desktop-page-width: 1600px;
	--desktop-page-min-width: calc(var(--desktop-content-min-width) - var(--body-scrollbar-width));
	--overlay-width: calc(100vw - var(--scrollbar-width));

	/* PRODUCT CARD */
	--card-vertical-gap: 8px;
	--card-gap: 16px;
}

html[data-device-mobile] {
	min-height: 100vh;
}

.geoConrol {
	position: relative;
	z-index: 100001;

	cursor: pointer;
	transition: all 0.2s ease;
	pointer-events: initial;

	border: 1px solid #D9D9D9;
	border-radius: 10px;
	background: #FFF;
	background-image: url("~src/assets/icons/navigation.svg");
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 24px;
}

.desktopGeoConrol {
	width: 48px;
	height: 48px;
}

.desktopGeoConrol:hover {
	background-color: var(--color-fill);
}

.desktopGeoConrol:active {
	box-shadow: inset 0 2px 5px rgb(0 0 0 / 15%);
}

.mobileGeoConrol {
	width: 44px;
	height: 44px;
}

.mobileGeoConrol:active {
	background-color: var(--color-fill);
	box-shadow: inset 0 2px 5px rgb(0 0 0 / 15%);
}

/* html {
	overflow-x: hidden;
} */

/* body {
	overflow: hidden;
	overflow-x: scroll;
} */

#__next {
	overflow: visible;

	width: 100vw;
	min-width: 300px;
	height: 100%;
}

html[data-device-desktop] #__next {
	width: calc(100vw - var(--body-scrollbar-width));
	min-width: var(--desktop-page-min-width);
}

/* html[data-keyboardshown] {
  --safe-area-inset-bottom: 0;
} */

/* html[data-hide-scroll], html[data-hide-scroll] body, body[data-hide-scroll] {
  overflow: hidden !important;
  position: relative !important;
}

html[data-hide-scroll-fixed], html[data-hide-scroll-fixed] body {
  position: fixed !important;
} */

body::-webkit-scrollbar {
	width: var(--body-scrollbar-width);
	height: var(--body-scrollbar-height);
}

/* Hide scrollbar for Chrome, Safari and Opera */
html[data-device-mobile] body * ::-webkit-scrollbar {
	display: none;

	width: 0 !important;
	height: 0 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
html[data-device-mobile] body * {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

html,
body {
	height: 100%;

	margin: 0;
	padding: 0;

	background: var(--color-white);

	font-family: "TT Norms", sans-serif;
	font-feature-settings: "liga" 0;
	text-rendering: optimizelegibility;
}

body:not(input):not(textarea) {
	user-select: none;
}

@supports ((height: max(10px, 20px)) and (padding-top: constant(safe-area-inset-top))) {
	:root {
		--safe-area-inset-top: constant(safe-area-inset-top);
		--safe-area-inset-left: constant(safe-area-inset-left);
		--safe-area-inset-right: constant(safe-area-inset-right);
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
	}
}

@supports ((height: max(10px, 20px)) and (padding-top: env(safe-area-inset-top))) {
	:root {
		--safe-area-inset-top: env(safe-area-inset-top);
		--safe-area-inset-left: env(safe-area-inset-left);
		--safe-area-inset-right: env(safe-area-inset-right);
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
	}
}

html[data-keyboardshown][data-with-notch] {
	--safe-area-inset-bottom: 0;
}

/* html[data-hide-scroll], body[data-hide-scroll] {
	overflow: hidden !important;
	position: relative !important;
}
 */

::-webkit-resizer {
	background-color: #666;
}

::-webkit-scrollbar-corner:hover {
	background-color: #EEF1F5;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #C0C1C1;
}

::-webkit-scrollbar-thumb:hover {
	border-radius: 5px;
	background-color: #8B8B8A;
}

::-webkit-scrollbar {
	width: var(--scrollbar-width);
	height: var(--scrollbar-height);
}

::-webkit-scrollbar-track {
	background-color: transparent;

	/* #f8f8f8 */
}

::-webkit-scrollbar-track:hover {
	background-color: #F0F0F0;
}

::-webkit-scrollbar-track-piece {
	background-color: transparent;
}

button,
input,
textarea {
	font-family: inherit;
}

a {
	text-decoration: none;

	color: inherit;
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

.h1,
.h2,
.h3,
.h4,
.h5 {
	margin: 0;

	color: var(--color-black);

	font-weight: 600;
	font-style: normal;
}

.h1 {
	letter-spacing: -1px;

	font-size: 38px;
	line-height: 44px;
}

.h2 {
	letter-spacing: -0.5px;

	font-size: 22px;
	line-height: 22px;
}

.h3 {
	font-size: 18px;
	line-height: 25px;
}

.h4 {
	font-size: 22px;

	line-height: 30px;
}

.h5 {
	font-size: 18px;
	line-height: 25px;
}

ul {
	padding: 0;

	list-style: none;
}

.text {
	color: var(--color-black);

	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
}

.clamp,
.clamp-2,
.clamp-3 {
	/* stylelint-disable-next-line value-no-vendor-prefix */
	display: -webkit-box;
	overflow: hidden;

	text-overflow: ellipsis;

	-webkit-box-orient: vertical;
}

.clamp {
	-webkit-line-clamp: 1;
}

.clamp-2 {
	-webkit-line-clamp: 2;
}

.clamp-3 {
	-webkit-line-clamp: 3;
}

.tinkoffPayRow {
	display: none;
}
