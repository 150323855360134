.content {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.text {
	margin: 16px 0;

	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.prompt {
	margin: 16px 0;

	letter-spacing: -0.01em;

	color: var(--color-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	line-height: 30px;
}

.phoneNubmer {
	cursor: pointer;
	text-decoration: underline;
	text-decoration-color: #A6A7A6;
	text-decoration-style: dashed;
}

.phoneNubmer:hover {
	text-decoration: none;
}

.editIcon {
	width: 20px;
	height: 20px;
	margin-bottom: -4px;
	margin-left: 4px;
}

.text {
	margin: 16px 0;

	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.inputWrapper {
	position: relative;
	z-index: 10;
}

.input {
	width: 100%;
	height: 48px;
	padding: 0 16px;

	border: 1px solid var(--color-border);
	border-radius: 12px;
	background: transparent;

	font-size: 16px;
	font-weight: 400;
	line-height: 48px;
}

.input::placeholder {
	letter-spacing: -0.01em;

	color: #757575;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.input:focus-visible {
	border: 1px solid #666;
	outline: none;
}

.inputWrapper.inputWrapperInvalid .input {
	display: flex;
	flex-direction: column;

	border: 1px solid #FF4C30;
}

.inputDisabled::after {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	content: "";
}

.alertSvg {
	position: absolute;
	top: 14px;
	right: 10px;

	display: none;

	width: 18px;
	height: 17px;
}

.alertPrompt {
	color: var(--color-primary);

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: 18px;
}

.alertSvg,
.alertPrompt {
	display: none;
}

.inputWrapper.inputWrapperInvalid .alertSvg,
.inputWrapper.inputWrapperInvalid .alertPrompt {
	display: unset;
}

.confirmBtn {
	width: 100%;
	margin-top: 16px;

	text-transform: uppercase;
}
