.text {
	margin: 0;
	margin-bottom: 16px;

	letter-spacing: -0.01em;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.inputWrapper {
	position: relative;
	z-index: 10;
}

.input {
	width: 100%;
	height: 48px;
	padding: 0 16px;

	border: 1px solid var(--color-border);
	border-radius: 12px;
	background: transparent;

	font-size: 16px;
	font-weight: 500;
	line-height: 48px;
}

.input::placeholder {
	letter-spacing: -0.01em;

	color: #757575;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.inputOrdering {
	background: var(--color-fill);
}

.input:focus-visible {
	border: 1px solid #666;
	outline: none;
}

.inputWrapper.inputWrapperInvalid .input {
	display: flex;
	flex-direction: column;

	border: 1px solid #FF4C30;
}

.inputDisabled::after {
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	content: "";
}

.alertSvg {
	position: absolute;
	top: 14px;
	right: 10px;

	display: none;

	width: 18px;
	height: 17px;
}

.alertPrompt {
	color: var(--color-primary);

	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: 18px;
}

.alertSvg,
.alertPrompt {
	display: none;
}

.inputWrapper.inputWrapperInvalid .alertSvg,
.inputWrapper.inputWrapperInvalid .alertPrompt {
	display: unset;
}

.confirmBtn {
	display: flex !important;
	align-items: center;
	justify-content: center;

	width: 100%;
	margin-top: 16px;

	text-transform: uppercase;
}

.icon {
	margin-right: 10px;
}

.icon path {
	fill: var(--color-white);
}

.confirmBtn:disabled .icon path {
	fill: var(--color-black);
}
