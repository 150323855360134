.header {
	width: 100%;

	transition: all 0.3s ease;

	border-bottom: 1px solid rgb(0 0 0 / 10%);

	background-color: var(--color-white);
}

.isScrollHeader {
	transition: all 0.3s ease;

	border-color: transparent;
	box-shadow: 0 7px 25px rgb(117 116 112 / 20%);
}

.headerContainer {
	display: flex;
	align-items: center;

	max-width: var(--desktop-page-width);
	height: 100%;
	margin: 0 auto;

	padding: 0 24px;
}

.btnback {
	margin-right: 32px;
}

.logoWrapper {
	display: block;
}

.logo {
	display: block;

	width: 160px;
	min-width: 160px;
	height: 36px;
	margin-right: 32px;

	background-image: url("~src/assets/svg/logo.svg");
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: contain;
}

.search {
	display: flex;
	flex: 1 1 auto;

	width: 100%;
	max-width: 420px;
	margin: 0;
}

.citySelect {
	margin: 0 auto 0 32px;
}

.buttonCart {
	margin-left: 32px;
}

.userAuth {
	margin-left: 32px;
}
