.wrapper {
	position: fixed;
	z-index: 10002;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;

	overflow: auto;
	justify-content: center;

	width: 100vw;
	height: 100vh;

	padding: 40px;
}

.window {
	z-index: 1002;

	display: flex;

	overflow: hidden;
	flex-direction: column;

	width: 100%;
	max-height: 85vh;

	margin: auto 0;
	padding: 20px;

	border-radius: 24px;
	background: var(--color-white);
}

.overlay {
	position: fixed;

	top: 0;
	left: 0;

	overflow: hidden;

	width: var(--overlay-width);
	height: 100vh;

	opacity: 0;

	background: var(--color-overlay);
	will-change: transform, opacity;
}

.wrapper.open .overlay {
	animation: fade 0.3s ease-in forwards;
}

.wrapper.close .overlay {
	animation: fade-out 0.3s ease-out forwards;
}

html:not([data-focused]) .wrapper {
	top: 0;
}

.wrapper.open .window {
	animation: slide-up 0.3s ease-in-out forwards;
}

.wrapper.close .window {
	animation: slide-out 0.3s ease-in-out forwards;
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes slide-up {
	from {
		transform: translateY(6%);
	}

	to {
		transform: translateY(0);
	}
}

@keyframes slide-out {
	0% {
		transform: translateY(0);
	}

	50% {
		opacity: 1;
	}

	100% {
		transform: translateY(6%);

		opacity: 0;
	}
}

.header {
	display: flex;
	align-items: flex-start;
	flex: 0 0 auto;
	flex-wrap: nowrap;

	width: 100%;
	min-height: 30px;
	margin-bottom: 16px;
}

.content {
	/* height: 100%; */
	overflow: hidden;
	flex: 1 1 auto;

	width: 100%;
}

.headerTitle {
	flex: 1 1 auto;
}

.mlTitle {
	margin-right: 0;
	margin-left: 30px;
}

.mrTitle {
	margin-right: 30px;
	margin-left: 0;
}

.controlContainer {
	flex: 0 0 auto;

	margin-top: -8px;
}

.leftControlContainer {
	float: left;

	width: 40px;
	height: 40px;
	margin-left: -8px;
}

.rightControlContainer {
	float: right;

	width: 40px;
	height: 40px;
	margin-right: -8px;
}

.closeButton {
	width: 100%;
	height: 100%;

	padding: 12px;

	cursor: pointer;
	transition: background-color 0.2s ease;

	border: none;

	border-radius: 12px;
	background-color: transparent;
	background-image: url("~/src/assets/icons/close.svg");
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: 40px 24px;
}

.closeButton:hover {
	background-color: var(--color-fill);
}

.closeButton:active {
	background-color: var(--color-fill-hover);
}
