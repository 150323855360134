.logo {
	display: unset;

	width: 160px;
	min-width: 160px;
}

.footer {
	display: flex;

	padding: 24px;

	border-top: 1px solid rgb(0 0 0 / 8%);
	background-color: var(--color-white);

	font-size: 16px;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
	max-width: 420px;
}

.lefCol {
	display: block;

	width: 100%;
	max-width: 400px;
}

.centerCol {
	margin-top: 40px;
}

.colLinks {
	display: flex;
	justify-content: space-between;

	width: 100%;
	margin: 22px 0;
}

.socLink {
	display: flex;
	align-items: center;

	width: 100%;
	width: 160px;
	margin-top: 15px;

	color: var(--color-gray-dark);
}

.infoLink {
	margin-right: 10px;
}

.socLinkText {
	margin-left: 10px;

	transition: color 0.2s ease;
}

.socLinkText:hover {
	color: var(--color-gray);
}

.appsCol {
	width: 100%;
	margin: 40px 10px 0 0;
}

.appsLinksWrapper {
	width: 100%;
	margin-top: 12px;
}

.appsLinksWrapper a:first-child {
	margin-right: 14px;
}

.desktopContent {
	flex-direction: row;

	max-width: 1600px;
	margin: 0 auto;
	padding: 0 24px;
}

.desktopContent .logo {
	display: unset;

	width: 160px;
	min-width: 160px;
}

.desktopContent .footer {
	padding: 24px 0 48px;

	border-top: 1px solid rgb(0 0 0 / 8%);
	background-color: var(--color-white);

	font-size: 16px;
}

.desktopContent .leftCol {
	width: 100%;
	margin-right: 45px;
}

.desktopContent .colLinks {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	width: 100%;
	margin: 0 14px 0 0;	/* 22px 14px 22px 0 */
}

.desktopContent .socLink {
	display: flex;
	align-items: center;

	width: 100%;
	width: 180px;
	margin-top: 15px;

	color: var(--color-gray-dark);
}

.desktopContent .infoLink {
	margin-top: 8px;/* margin-bottom: 8px; */
	margin-right: 10px;
}

.desktopContent .socLinkText {
	margin-left: 10px;
}

.desktopContent .centerCol {
	margin-top: 0;
	margin-right: 45px;
}

.desktopContent .appsCol {
	width: 100%;
	margin: 0;

	text-align: right;
}

.desktopContent .appsLinksWrapper {
	margin-top: 12px;
}

.desktopContent .appsLinksWrapper a:first-child {
	margin-right: 0;
}

.desktopContent .appsLinksWrapper a:last-child {
	margin-left: 14px;
}
