.counter {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.counterBtn {
	width: 44px;
	padding: 0;
}

.btnSvg {
	width: 22px;
	height: 22px;
}

.count {
	position: relative;

	padding: 18px 20px;

	font-size: 16px;
	font-weight: 500;
}

.sht {
	position: absolute;
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
}
