.addressButton {
	min-width: 280px;
	max-width: 500px;
}

.addressButton:hover {
	background-color: var(--color-silver-hover);
}

.addressButton:active {
	background-color: var(--color-silver-active);
}

.addressContainer {
	display: flex;
	overflow: hidden;
	align-items: center;

	width: 100%;

	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}

.addressWrapper {
	display: flex;
	overflow: hidden;
	align-items: flex-start;
	flex: 1 1 auto;
	flex-direction: column;
}

.addressText {
	display: flex;
	overflow: hidden;
	align-items: center;
	flex-wrap: nowrap;

	width: 100%;
}

.addressCaption {
	font-size: 12px;
	line-height: 10px;
}

.addressStreet {
	display: inline-block;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;

	line-height: 20px;
}

.addressHouse {
	display: inline-block;
}

.arrowIcon {
	flex: 0 0 auto;

	margin-top: 8px;
	margin-left: 4px;

	pointer-events: none;
	stroke: var(--color-gray-dark);
}

.emptyAddress {
	display: flex;
	align-items: center;
	flex-direction: row;

	transition: all 0.3s ease;

	background-color: var(--color-silver);
}

.emptyAddress:hover {
	background-color: var(--color-silver-hover);
}

.emptyAddress:active {
	background-color: var(--color-silver-active);
}

.locationIcon {
	flex: 0 0 auto;

	width: 24px;
	height: 24px;
	margin-top: -2px;
	margin-right: 6px;
}

.emptyText {
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}
