.mobilePlaceholderCard {
	width: 100%;
	height: 284px;
	padding: 6px;

	border-radius: 18px;
	box-shadow: 0 2px 8px rgb(122 122 122 / 9%);
}

.desktopPlaceholderCard {
	width: auto;
	min-width: 150px;
	height: 352px;
	padding: 6px;

	border-radius: 20px;
	box-shadow: 3px 7px 20px rgb(122 122 122 / 9%);
}

.partnerCardPlaceholder {
	width: auto;
	min-width: 150px;
	height: 266px;

	border-radius: 18px;
	box-shadow: 3px 7px 20px rgb(122 122 122 / 9%);
}
