.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 40px;
}

.text {
	display: flex;

	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
}

.crockeryIcon {
	margin-right: 8px;
}

.plusIcon {
	width: 18px;
	height: 18px;
	margin-right: 8px;
}

.crockeryIcon path {
	fill: black;
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;

	letter-spacing: -0.025rem;
	text-transform: uppercase;
}

.wrapper .cutleryCounter {
	margin-top: 0;
}
