.wrapper {
	position: relative;

	display: flex;
	flex-direction: row;

	width: 100%;
	height: auto;

	background: var(--color-white);
}

.left {
	position: relative;

	width: 320px;
	height: 320px;
	margin-right: 16px;
}

.imgWrapper {
	overflow: hidden;

	width: 320px;
	height: 320px;

	border-radius: 18px;
}

.img {
	border-radius: 18px;
}

.right {
	position: relative;

	display: flex;
	overflow-y: scroll;
	flex: 1 1 auto;
	flex-direction: column;
	justify-content: space-between;

	height: auto;
	margin-right: var(--scrollbar-width);
}

.info {
	padding-bottom: 130px;
}

.infoItem {
	margin-bottom: 16px;

	font-size: 16px;
	font-weight: 500;
}

.bottomBar {
	position: absolute;
	bottom: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;
	padding-top: 12px;

	border-top: solid 1px #EFEFEF;
	background: #FFF;
}

.counterWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 16px;
}

.forOnePrice {
	margin-bottom: 4px;

	color: var(--color-black);

	font-size: 16px;
}

.total {
	font-size: 18px;
	font-weight: 500;
}

.totalPrice {
	color: var(--color-primary);
}
