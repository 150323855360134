.tag {
	position: relative;

	display: inline-flex;
	align-items: center;

	flex-direction: column;

	text-align: start;

	border-radius: 12px;

	background: none;
}

.imageWrapper {
	position: relative;

	overflow: hidden;

	width: 72px;
	height: 72px;

	border-radius: 50%;
}

.title {
	margin-top: 12px;

	text-align: center;

	color: var(--color-black);

	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}
