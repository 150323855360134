.desktopContainerMap {
	display: flex;
	flex-direction: column;

	height: 500px;

	margin: 0;

	background: var(--color-white);
}

.editableContainerMap {
	height: 600px;
}

.mapContainer {
	position: relative;
	position: relative;

	overflow: hidden;
	flex: 1 1 auto;

	width: 100%;
	height: 100%;

	border-radius: 0 0 24px 24px;
}

.desktopAddressBlock {
	position: relative;

	display: flex;
	flex-direction: column;

	margin-bottom: 24px;
}

.addressTopBar {
	display: flex;
	flex-wrap: nowrap;
}

.addressSearchBar {
	flex-grow: 1;

	width: 100%;
	margin-right: 14px;
}

.suggestPopup {
	position: absolute;
	z-index: 10010;
	top: 110%;
	left: 10px;

	width: 80%;
	padding: 16px;

	list-style: none;

	text-align: left;

	border-radius: 16px;
	background: #FFF;
	box-shadow: 0 8px 24px rgb(0 0 0 / 16%);
}

.suggestList {
	margin: 0;
	padding: 0;

	list-style: none;
}

.listItem {
	padding: 0 12px;

	cursor: pointer;
	transition: background-color 0.2s ease;

	border-radius: 12px;

	line-height: 40px;
}

.listItem:hover {
	background-color: var(--color-fill-hover);
}

.selectedlistItem {
	background-color: var(--color-fill-hover);
}

.button {
	flex: 0 0 auto;
}

.addressDetails {
	display: flex;
	flex-direction: column;
}

.title {
	margin-top: 24px;
	margin-bottom: 12px;

	text-align: left;
}

.titleNote {
	color: var(--color-overlay);
}

.subDetails {
	/* display: flex;
	flex-wrap: nowrap; */
	display: grid;
	gap: 8px 8px;

	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.preference {
	width: 100%;
	height: 64px;
	margin-top: 12px;

	resize: none;
}
