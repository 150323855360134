.card {
	position: relative;

	display: flex;
	flex-direction: column;

	padding: 10px;

	transition: 0.3s ease;

	border-radius: 20px;
	background-color: var(--color-white);
	box-shadow: 3px 7px 20px rgb(122 122 122 / 9%);
}

.cardCover {
	position: absolute;
	z-index: 3;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;

	cursor: pointer;
	pointer-events: painted;
}

.imageWrapper {
	position: relative;

	overflow: hidden;
	flex-shrink: 0;

	width: 100%;
	min-width: 132px;
	height: 168px;

	border-radius: 20px;
	outline: none;
	background: rgb(221 221 221);
}

.persentPlate {
	position: absolute;
	top: 7px;
	right: 7px;

	display: none;

	padding: 0 12px;

	letter-spacing: -0.01em;

	color: #FFF;
	border-radius: 14px;

	background: #FF6565;

	font-size: 18px;
	font-weight: 600;
	line-height: 32px;
	mix-blend-mode: normal;
}

.visiblePersentPate {
	display: unset;
}

.productInformation {
	position: relative;

	display: flex;
	flex-direction: column;

	height: 164px;
}

.price {
	display: flex;
	align-items: flex-end;

	height: 38px;
	margin-top: 6px;

	color: var(--color-black);

	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
}

.hidden {
	display: none;
}

.oldCost {
	text-decoration: line-through;

	font-size: 14px;
	line-height: 16px;
}

.cost {
	font-size: 20px;
}

.redPrice {
	color: #F00;
}

.characteristic {
	margin-left: auto;

	color: var(--color-gray);

	font-size: 16px;
}

.name {
	overflow: hidden;

	margin: auto 0;

	letter-spacing: -0.025rem;

	color: var(--color-dull-black);

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}
