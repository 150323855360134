.header {
	width: 100%;

	transition: border, box-shadow 0.3s ease;

	border-bottom: 1px solid rgb(0 0 0 / 10%);

	background-color: var(--color-white);
}

.isScrollHeader {
	transition: border, box-shadow 0.3s ease;

	border-color: transparent;
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 100%;
}

.search {
	margin-right: 16px;
}
