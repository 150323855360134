.buttonTimePicker {
	display: flex;
	align-items: center;

	cursor: pointer;

	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
}
