.wrapper {
	position: relative;

	display: flex;
	align-items: center;

	width: 100%;
	height: 48px;
}

.field {
	width: 100%;
	height: 100%;
	padding: 0 34px;

	text-overflow: ellipsis;

	color: var(--color-black);

	border: 1px rgb(0 0 0 / 0%) solid;
	border-radius: 12px;
	outline: none;
	background-color: var(--color-fill);

	font-size: 16px;
}

.button {
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 34px;
	height: 44px;

	cursor: default;
	pointer-events: none;

	border: none;

	border-radius: 12px 0 0 12px;
	border-radius: 12px 0 0 12px;
	background-color: transparent;
}

.searchIcon {
	width: 22px;
	height: 22px;

	opacity: 0.5;
}

.clear {
	position: absolute;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 34px;
	height: 44px;
	margin-right: 0;
	padding: 0;

	cursor: pointer;

	border: none;
	border-radius: 0 12px 12px 0;
	background-color: transparent;
}

.clearIcon {
	width: 16px;
	height: 44px;

	opacity: 0.5;
	border: none;
}

.withButtonSearch .searchButton {
	position: absolute;
	right: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 114px;
	padding: 0;

	cursor: pointer;
	text-transform: uppercase;

	color: #FFF;

	font-weight: 400;
}

.withButtonSearch .field {
	padding-right: 152px;
}

.withButtonSearch .clear {
	right: 126px;
}
