.partnerAbout {
	margin-bottom: 8px;
}

.inlinePartnerAbout {
	position: relative;

	display: flex;
	overflow: hidden;

	width: 100%;
	margin-bottom: 16px;
	padding-bottom: 0;

	border-bottom: 0;
}

.imageWrapper {
	position: relative;

	overflow: hidden;

	width: 96px;
	height: 96px;
	margin: 4px;
	margin-right: 24px;

	border-radius: 50%;
	background-color: rgb(202 202 202);
}

.placeholderImage {
	width: 96px;
	height: 96px;

	border-radius: 50%;
	background-color: var(--color-fill);
}

.info {
	display: flex;
	flex-direction: column;

	width: calc(100% - 124px);
	margin: 4px 0;
	margin-right: 12px;

	background: none;
}

.inlineInfo {
	width: auto;
	margin: 0;
}

.topBar {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 16px;
}

.partnerShortName {
	letter-spacing: -0.01em;

	color: #999;

	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.favorite {
	display: flex;
}

.buttonsWrapper {
	display: flex;
	overflow: auto;
	flex-wrap: wrap;

	width: 100%;

	white-space: nowrap;

	color: var(--color-black);
	gap: 8px;
}
