.repeatCode {
	margin-top: 12px;

	cursor: pointer;
	text-align: center;
	letter-spacing: -0.01em;

	color: var(--color-primary);

	font-size: 14px;

	font-weight: 400;
	line-height: 24px;
}

.repeatCodeAfter {
	color: var(--color-gray-dark);
}
