.tag {
	position: relative;

	display: inline-flex;
	align-items: center;

	flex-direction: column;

	text-align: center;

	background: none;
}

.title {
	margin-top: 6px;

	color: rgb(0 0 0 / 40%);

	font-size: 12px;
	font-weight: 600;
}

@media screen and (min-width: 1280px) {
	.tag {
		align-items: center;
		flex-direction: row;

		padding: 8px 16px 8px   12px;

		transition: background-color 0.2s ease;

		text-align: start;

		border-radius: 12px;
		background-color: var(--color-fill);
	}

	.tag:hover {
		transition: background-color 0.2s ease;

		background-color: var(--color-fill-hover);
	}

	.title {
		margin-top: unset;
		margin-left: 12px;

		color: #212428;

		font-size: 16px;
		line-height: 22px;
	}
}
