.wrapper {
	width: 100%;
	height: 100%;
	margin: 16px 0;
}

.authStage {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;
	height: 100%;
}

.enter {
	opacity: 0;
}

.exit {
	opacity: 1;
}

.enterActive {
	opacity: 1;
}

.exitActive {
	opacity: 0;
}

.enterActive,
.exitActive {
	transition: opacity 300ms;
}
