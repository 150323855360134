.ldsDualRing {
	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

.ldsDualRing::after {
	display: block;

	width: 24px;
	height: 24px;

	content: " ";
	animation: ldsdualring 1.2s linear infinite;

	border: 3px solid var(--color-primary);
	border-color: var(--color-primary) transparent var(--color-primary) transparent;
	border-radius: 50%;
}

.colorWhite.ldsDualRing::after {
	border: 2px solid #FFF;
	border-color: #FFF transparent;
}

.screenLoading {
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100vw;
	height: 100vh;

	background-color: rgb(255 255 255 / 50%);
}

.screenLoading::after {
	position: absolute;
	top: calc(50% - 18px);
	left: calc(50% - 18px);

	display: block;

	width: 36px;
	height: 36px;

	content: " ";
	animation: ldsdualring 1.2s linear infinite;

	border: 4px solid var(--color-primary);
	border-color: var(--color-primary) transparent var(--color-primary) transparent;
	border-radius: 50%;
}

.colorWhite.screenLoading::after {
	border: 4px solid #FFF;
	border-color: #FFF transparent;
}

@keyframes ldsdualring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
