.wrapper {
	position: fixed;

	z-index: 10002;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.overlay {
	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;

	will-change: opacity;

	background: var(--color-overlay);
}

.wrapper.open .overlay {
	animation: fade 0.4s ease-in-out forwards;
}

.wrapper.close .overlay {
	animation: fade-out 0.4s ease-out forwards;
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.window {
	position: absolute;

	z-index: 10002;
	bottom: 0;

	display: flex;
	overflow: hidden;
	flex-direction: column;
	justify-content: center;

	/* bottom: calc(0px + var(--safe-area-inset-bottom, 0px)); */

	width: 100%;

	transition-property: transform, opacity;

	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	background: var(--color-white);
}

.wrapper.open .window {
	animation: slide-up 0.4s ease-in-out forwards;
}

.wrapper.close .window {
	animation: slide-out 0.4s ease-in-out forwards;
}

.fullScreen {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	height: 100%;
	padding-top: max(calc(0px + var(--safe-area-inset-bottom, 0px)), 0px);
	padding-bottom: max(calc(0px + var(--safe-area-inset-bottom, 0px)), 0px);

	border-radius: 0;
}

@keyframes slide-up {
	from {
		transform: translateY(100%);
	}

	to {
		transform: translateY(0);
	}
}

@keyframes slide-out {
	0% {
		transform: translateY(0);
	}

	70% {
		opacity: 1;
	}

	100% {
		transform: translateY(100%);

		opacity: 0;
	}
}

.header {
	display: flex;
	align-items: center;
	flex: 0 0 auto;
	flex-wrap: nowrap;

	width: 100%;
	padding: 0 16px;
	padding-bottom: 16px;

	line-height: 56px;
}

.content {
	overflow: hidden;
	flex: 1 1 auto;

	width: 100%;

	/* height: 100%; */
	padding: 24px 16px;
	padding-top: 0;
}

.mlTitle {
	margin-right: 0;
	margin-left: 56px;
}

.mrTitle {
	margin-right: 56px;
	margin-left: 0;
}

.headerTitle {
	flex: 1 1 auto;

	padding: 0 8px;
}

.controlContainer {
	flex: 0 0 auto;
}

.leftControlContainer {
	float: left;

	width: 62px;
	height: 56px;
	margin-left: -16px;
}

.rightControlContainer {
	float: right;

	width: 62px;
	height: 56px;
	margin-right: -16px;
}

.closeButton {
	width: 100%;
	height: 100%;

	padding: 0;

	border: none;

	border-radius: 12px;
	background-color: transparent;
	background-image: url("~/src/assets/icons/close.svg");
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: 62px 24px;
}

.closeIcon {
	display: unset;

	width: 62px;
}

.hideWindowBtn {
	position: absolute;
	z-index: 101;
	top: 12px;
	right: 12px;

	width: 42px;
	height: 42px;
	margin-top: 0;
	margin-left: auto;
	padding: 0;

	border: none;
	border-radius: 50%;
	background-color: var(--color-white);
	background-image: url("~/src/assets/icons/close.svg");
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: 42px 24px;
	box-shadow: 0 4px 2px 2px rgb(68 65 65 / 10%);
}
